import { styled } from "styled-components";

export const InputContainer = styled.div`
  .label {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
    color: #1e293b;
    margin-bottom: 10px;
  }

  .input-container {
    width: 100%;
    font-size: 16px;
    border: 2px solid #e0e0e2;
    border-radius: 10px;
    padding: 12px;
    /* margin-top: 10px; */

    &::placeholder {
      color: #c5c6cc;
    }
  }
  @media (max-width: 991px) {
    .input-container {
      margin-top: 0px;
    }
  }
  @media (max-width: 767px) {
  }
  @media (max-width: 575px) {
    .label {
      margin-bottom: 4px;
      font-size: 18px;
    }
    .input-container {
      padding: 8px;
    }
  }
`;
