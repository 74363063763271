import React from "react";
import { AboutUsPageContainer } from "./AboutUsPageContainer";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Card, Col, Container, Row } from "react-bootstrap";
import aboutUsImg from "../../assets/images/aboutUs.png";
import missionImg from "../../assets/images/about-us/about3.png";
import visionImg from "../../assets/images/about-us/about2.png"
import valueImg from "../../assets/images/about-us/about1.png"

const AboutUsPage = () => {
  return (
    <AboutUsPageContainer>
      <Header />
      <div className="allContent">
        <div style={{ padding: "20px 0" }}>
          <Container>
            <Row>
              <Col lg={12}>
                <div>
                  <Card className="customCard p-2">
                    <Card.Body>
                      <Row>
                        <Col lg={7} xs={12} className="wwaLeft">
                          <div className="top-content">
                            <span className="title">Who We Are</span>
                          </div>
                          <div className="sub-title mt-2">
                            <span className="sub-title-content">
                              SmaartCard is a cutting-edge loyalty and rewards
                              platform designed to connect consumers with
                              exclusive discounts and offers from a wide range
                              of partners. From healthcare to dining,
                              entertainment, and more, we empower our users to
                              make smarter purchasing decisions while enjoying
                              significant savings. Founded in 2024, we are part
                              of JRD Card LLP, a forward-thinking company
                              dedicated to innovation and delivering exceptional
                              value through smart solutions.
                            </span>
                          </div>
                        </Col>
                        <Col lg={5} xs={12} className="wwaRight">
                          <div className="img-side">
                            <img
                              src={aboutUsImg}
                              alt="about us img"
                              className="img-fluid"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="missionSection">
          <Container>
            <Row>
              <Col lg={4} xs={12} className="">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-img">
                          <img
                            src={missionImg}
                            alt="mission"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-title">Our Mission</div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                            To simplify access to discounts and rewards,
                            enabling consumers to save more on their everyday
                            purchases.
                          </p>
                          <p>
                            To help businesses grow by connecting them with
                            engaged, loyal customers through exclusive offers.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} xs={12} className=" mt-lg-0 mt-3">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-img">
                          <img
                            src={visionImg}
                            alt="visionImg"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-title">Our Vision</div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                          To revolutionize the loyalty and rewards landscape by becoming a trusted partner for both consumers and businesses.
                          </p>
                          <p>
                          To expand globally, offering our services to millions of users while maintaining a personalized and customer-centric approach.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} xs={12} className=" mt-lg-0 mt-3">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-img">
                          <img
                            src={valueImg}
                            alt="valueImg"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-title">Our Values</div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                          Innovation: Continuously enhancing our platform to provide the best user experience and keep ahead of industry trends.
                          </p>
                          <p>
                          Integrity: Building trust with users and partners by delivering reliable and valuable services.
                          </p>
                          <p>
                          Customer Focus: Ensuring that both consumers and business partners receive meaningful value through our offerings.
                          </p>
                         
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <div className="p-3">
              <span className="title-offer">What We Offer</span>
            </div>
            <Col lg={6} xs={12}>
              <Card className="kpo" style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">01</span>

                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Exclusive Discounts</span>
                    <span className="subtitle mt-1">
                      Access to offers from various industries, including
                      healthcare, restaurants, and entertainment venues
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} xs={12} className="mt-lg-0 mt-3">
              <Card className="kpo" style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">02</span>
                  <div className="d-flex flex-column  mt-lg-2 mt-3">
                    <span className="title">Business Partnerships</span>
                    <span className="subtitle mt-1">
                      A platform for businesses to reach new customers and
                      foster long-term loyalty.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </AboutUsPageContainer>
  );
};

export default AboutUsPage;
