import { useDispatch } from "react-redux";
import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";
import { setPartnerList } from "../store/Partner/PartnerListSlice";

const usePartnerList = () => {
  const dispatch = useDispatch();
  // const [loadingPartners, setLoadingPartners] = useState(false);

  const getPartnerList = async (
    page,
    length,
    cityName = "",
    categoryId = 0,
    offerId = 0
  ) => {
    const partnerRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/smartcard?page=${page}&size=${length}&cityName=${cityName}&categoryId=${categoryId}&offerId=${offerId}`,
      "GET",
      null
    );

    if (partnerRes?.data?.status) {
      // setLoadingPartners(false);
      dispatch(setPartnerList(partnerRes?.data?.response));
    } else {
      // setLoadingPartners(false);
      dispatch(setPartnerList({}));
    }
  };

  return { getPartnerList };
};

export default usePartnerList;
