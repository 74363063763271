import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { UserProfileContainer } from "./UserProfileContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { RiPencilLine } from "react-icons/ri";
import { CiCamera } from "react-icons/ci";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonModal from "../../components/Modal/CommonModal";
import Button from "react-bootstrap/Button";
import { ModalContainer } from "./ModalContainer";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { setUserData } from "../../store/User/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../assets/images/imagePlaceholder.png";
import smartCartImg from "../../assets/images/smartCard.jpg";
import Loader from "../../components/Loader/Loader";
import { toastMessage } from "../../utils/toastUtils";
import { CiCircleQuestion } from "react-icons/ci";
import { useCity } from "../../Hooks/useCity";

const UserProfilePage = () => {
  const userData = useSelector((state) => state.userData);

  const [showEditModal, setShowEditModal] = useState(false);

  const [loading, setLoading] = useState("");

  const [cityValue, setCityValue] = useState(userData?.city);

  const { cityList } = useCity();

  const fileRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showImgModal, setShowImgModal] = useState(false);
  const [subscribeData, setSubscribeData] = useState({});

  const [imgFile, setImgFile] = useState("");

  const handleShow = () => setShowEditModal(true);

  const handleImgShow = () => setShowImgModal(true);

  const emailRegex =
    /^(?!.*[^A-Za-z0-9]{2})[A-Za-z0-9]\.*(?=[A-Za-z][a-zA-Z0-9@._-]{5,50}$)[a-zA-Z0-9._-]{1,40}@(?:(?=[a-zA-Z][a-zA-Z0-9-]{1,15}\.)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*\.){1,2}[a-zA-Z]{2,6}$/;

  const profileSchema = yup.object().shape({
    name: yup
      .string()
      .required("Please Enter Your Name")
      .matches(/^[a-z A-Z-]+$/, "Enter Valid Name")
      .min(2, "Name should be 2 to 30 characters")
      .max(30, "Name Should be less then 30 characters"),
    email: yup
      .string()
      .required("Please Enter Email")
      .matches(emailRegex, "Enter Valid Email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: userData?.name,
      email: userData?.email,
      mobileNo: userData?.phone,
    },
    resolver: yupResolver(profileSchema),
    mode: "onChange",
  });

  const handleClose = () => {
    reset();
    setShowEditModal(false);
  };

  const onCityChange = (e) => {
    setCityValue(e.target.value);
  };

  const handleImgClose = () => {
    setShowImgModal(false);
    setImgFile(null);
    setPreview(null);
  };

  const onSubmit = async (data) => {
    const editRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/user/${localStorage.getItem("userId")}`,
      "PUT",
      {
        name: data.name,
        phone: data.mobileNo,
        email: data.email,
        city: cityValue,
      }
    );

    if (editRes?.data?.status) {
      setShowEditModal(false);
      getProfileDetails();
    }
  };

  const getProfileDetails = async () => {
    setLoading("profileData");
    const profileRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/user/${localStorage.getItem("userId")}`,
      "GET",
      null
    );

    if (profileRes?.data?.status) {
      setLoading("");
      dispatch(setUserData(profileRes?.data?.response));
    }
  };

  const getSubscriptionDetails = async () => {
    setLoading("subscriptionData");
    const subRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/subcription/subscriptionByPatient/${localStorage.getItem(
        "userId"
      )}`,
      "GET",
      null
    );

    if (subRes?.data?.status) {
      setLoading("");
      setSubscribeData(subRes?.data?.response);
    } else {
      setSubscribeData(subRes?.data?.response);
    }
  };

  useEffect(() => {
    getProfileDetails();
    getSubscriptionDetails();
  }, []);

  const modalContent = () => {
    return (
      <ModalContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col as={Col} md="6" xs="12">
              <label className="labelName">Name</label>
              <div>
                <input
                  type="text"
                  control={control}
                  name={"name"}
                  {...register("name")}
                />
                {errors?.name && (
                  <p className="error-message">{errors?.name?.message}</p>
                )}
              </div>
            </Col>
            <Col as={Col} md="6" xs="12">
              <label className="labelName">Email</label>
              <div>
                <input
                  type="text"
                  control={control}
                  name={"email"}
                  {...register("email")}
                />
                {errors?.email && (
                  <p className="error-message">{errors?.email?.message}</p>
                )}
              </div>
            </Col>
            <Col as={Col} md="6" xs="12">
              <label className="labelName">Mobile No.</label>
              <div>
                <input
                  type="Number"
                  {...register("mobileNo")}
                  control={control}
                  name={"mobileNo"}
                  disabled
                />
              </div>
            </Col>

            <Col as={Col} md="6" xs="12">
              <label className="labelName">Select City</label>
              <Form.Select
                aria-label="Default select example"
                className="headselect"
                value={cityValue}
                onChange={onCityChange}
              >
                <option>Select City</option>
                {cityList?.map((ct) => {
                  return (
                    <option key={ct.cityId} value={ct.cityName}>
                      {ct.cityName}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>

            <Col as={Col} md="12" className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                style={{ background: "#024089", borderColor: "#024089" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </ModalContainer>
    );
  };

  const [preview, setPreview] = useState(null);

  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append("file", imgFile);

    const imgRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/user/profile/uploadFile?user_id=${localStorage.getItem(
        "userId"
      )}`,
      "POST",
      formData,
      true
    );

    if (imgRes?.data?.status) {
      setShowImgModal(false);
      getProfileDetails();
    }
  };

  const handleChangeImg = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size <= 10 * 1024 * 1024) {
      setImgFile(selectedFile);
    } else {
      toastMessage("File size should be less than 10MB", "error");
      e.target.value = null;
    }
  };

  useEffect(() => {
    if (imgFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imgFile);
      reader.onload = () => {
        setPreview(reader.result);
      };
    }
  }, [imgFile]);

  const modalImgContent = () => {
    return (
      <ModalContainer>
        <Row>
          <Col xs="12" className="mb-4">
            <div className="modelProfileImg">
              <input
                hidden
                name="profilePhoto"
                type="file"
                accept="image/png, image/bmp, image/jpeg"
                ref={fileRef}
                onChange={(e) => handleChangeImg(e)}
              />
              <img
                src={
                  preview !== null
                    ? preview
                    : userData?.patientLogo
                    ? userData?.patientLogo
                    : defaultImg
                }
                alt="demo_img"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  fileRef.current.click();
                }}
              />
            </div>
          </Col>
          <Col xs="6" style={{ textAlign: "end" }}>
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => {
                fileRef.current.click();
              }}
              style={{
                background: "#e9eaec",
                borderColor: "#e9eaec",
                color: "#000",
                fontWeight: "500",
              }}
            >
              Select Image
            </Button>
          </Col>
          <Col xs="6" style={{ textAlign: "start" }}>
            <Button
              variant="primary"
              size="sm"
              style={{
                background: "#024089",
                borderColor: "#024089",
                fontWeight: "500",
              }}
              onClick={handleUploadImage}
              disabled={!imgFile}
            >
              Upload Image
            </Button>
          </Col>
        </Row>
      </ModalContainer>
    );
  };

  if (loading === "profileData" || loading === "subscriptionData") {
    return <Loader />;
  }

  return (
    <>
      <UserProfileContainer>
        <Header />
        <div className="allContent">
          <Container>
            <Row>
              <Col lg={7}>
                <div>
                  <Card className="customCard">
                    <Card.Body>
                      <div className="profileHead">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="imgContent">
                            <img
                              onClick={handleImgShow}
                              className="profileImg"
                              src={userData?.patientLogo || defaultImg}
                              alt="profileimg"
                            />
                            <div className="camera">
                              <CiCamera
                                onClick={handleImgShow}
                                style={{ cursor: "pointer" }}
                                className="cameraIcon"
                              />
                              <CommonModal
                                modalTitle={"Edit Profile Picture"}
                                show={showImgModal}
                                handleClose={handleImgClose}
                                modalBody={modalImgContent()}
                              />
                            </div>
                          </div>
                          <span className="profileName">{userData?.name}</span>
                        </div>
                        <div>
                          <RiPencilLine
                            className="editProfileIcon"
                            onClick={handleShow}
                            style={{ cursor: "pointer" }}
                          />
                          <CommonModal
                            modalTitle={"Update Details"}
                            show={showEditModal}
                            handleClose={handleClose}
                            modalBody={modalContent()}
                          />
                        </div>
                      </div>
                      <div className="profileContent">
                        <div
                          className="pc"
                          style={{ color: "#024089", fontWeight: "bold" }}
                        >
                          <span className="pct mb-2">MPIN</span>:
                          <span className="pcc">
                            {userData?.mpin || "-"}{" "}
                            <span className="ml-2">
                              <CiCircleQuestion
                                className="fas fa-question-circle"
                                style={{
                                  color: "blue",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                title="If you feel your MIP is being used by someone, please contact Smaartcard."
                              />
                            </span>
                          </span>
                        </div>
                        <div className="pc">
                          <span className="pct mb-2">Email</span>:
                          <span className="pcc">{userData?.email || "-"}</span>
                        </div>
                        <div className="pc">
                          <span className="pct mb-2">Mobile No.</span>:
                          <span className="pcc">{userData?.phone || "-"}</span>
                        </div>

                        <div className="pc">
                          <span className="pct mb-2">City</span>:
                          <span className="pcc">{userData?.city || "-"}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={5} className="mt-lg-0 mt-3">
                <div className="customsmartcardImg">
                  <img
                    className="staticSmartImg img-fluid"
                    src={smartCartImg}
                    alt="smart card img"
                  />
                  <span className="smartNo">
                    {userData?.cardNumber || "Card number not available"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={7}>
                <div>
                  <span className="navititle">Quick Navigation</span>

                  <Card
                    onClick={() => navigate("/redeemed-packages-user")}
                    className="redeembtn mt-2"
                  >
                    {/* <FcLikePlaceholder /> */}
                    <Card.Body>View Redeemed Packages</Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <span className="navititle">Reedem coins</span>
                  <Card
                    onClick={() => navigate("/redeemed-coins-user")}
                    className="redeembtn mt-2"
                  >
                    <Card.Body>View your redeemed coins</Card.Body>
                  </Card>
                </div>
              </Col>
              <Col lg={5} className="mt-lg-0 mt-3">
                <div className="sd">
                  <Card className="customCard">
                    <Card.Body>
                      <div className="profileHead">
                        <span className="profileName">
                          Subscription Details
                        </span>
                      </div>
                      {subscribeData !== null ? (
                        <div className="profile1Content">
                          <div className="pc">
                            <span className="pct mb-2">Subscription Name</span>:
                            <span className="pcc">
                              {subscribeData?.subscriptionMasterName || "-"}
                            </span>
                          </div>

                          <div className="pc">
                            <span className="pct mb-2">Due Date</span>:
                            <span className="pcc">
                              {subscribeData?.endTime || "-"}
                            </span>
                          </div>
                          <div className="pc">
                            <span className="pct mb-2">Card Number</span>:
                            <span className="pcc">
                              {subscribeData?.cardNumber || "-"}
                            </span>
                          </div>
                          <div className="pc">
                            <span
                              className="pct mb-2"
                              style={{
                                color:
                                  subscribeData?.currentStatus === 0
                                    ? "green"
                                    : "red",
                                fontWeight: "bold",
                              }}
                            >
                              Current Status
                            </span>
                            : &nbsp;
                            {subscribeData?.currentStatus !== null ? (
                              <span
                                style={{
                                  color:
                                    subscribeData?.currentStatus === 0
                                      ? "green"
                                      : "red",
                                  fontWeight: "bold",
                                }}
                              >
                                {subscribeData?.currentStatus === 0
                                  ? "Active"
                                  : subscribeData?.currentStatus === 1
                                  ? "Inactive"
                                  : "-"}
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="m-2 p-2">No Subscription Found.</div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </UserProfileContainer>
    </>
  );
};

export default UserProfilePage;
