import { styled } from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  padding: 30px 0;
  background-color: #024089;
  /* .footerSocialIcon{display:flex;align-items:center;justify-content:space-between;} */
  .footerTitle {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }
  .footerIcon {
    color: #fff;
    font-size: 30px;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-right: 10px;
  }
  .footerList {
    text-decoration: none;
    padding: 0;
    list-style-type: none;
  }
  .footerList li a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
  }

  .footerLast {
    color: #fff;
    text-align: center;
    border-top: 1px solid #fff;
    padding: 20px 0 0;
    margin-top: 20px;
  }

  @media (min-device-width: 991px) and (max-device-width: 1200px) {
    .footerTitle {
      font-size: 18px;
    }
  }
  @media (max-width: 575px) {
    .footerIcon {
      margin-right: 6px;
    }
    .footerstore img {
      width: 100px;
    }
    .footerLast {
      font-size: 13px;
    }
    .footerLast span {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  @media (max-width: 400px) {
    .footerTitle {
      font-size: 16px;
    }
    .footerList li a {
      font-size: 14px;
    }
    .footerIcon {
      font-size: 27px;
    }
  }
`;
