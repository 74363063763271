import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { OwnedPackagesPageContainer } from "./OwnedPackagesPageContainer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import { IoChevronBack } from "react-icons/io5";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const OwnedPackagesPage = () => {
  const [ownedPackages, setOwnedPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null); // New state to track selected package
  const partnerId = localStorage.getItem("partnerId");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getOwnedPackages = async () => {
      setLoading(true);
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/master/package/partnerPackages/${partnerId}`,
        "GET",
        null
      );

      if (res?.data?.status) {
        setLoading(false);
        setOwnedPackages(res?.data?.response);
      } else {
        setLoading(false);
        setOwnedPackages([]);
      }
    };

    getOwnedPackages();
  }, []);

  const handlePackageClick = (packageObj) => {
    setSelectedPackage(packageObj);
  };

  const handleBack = () => {
    setSelectedPackage(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <OwnedPackagesPageContainer>
      <Header />
      <div className="allContent content">
        <Container>
          <Row>
            <Col xs={12}>
              {selectedPackage ? (
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <div className="d-flex align-items-start">
                        <button
                          onClick={handleBack}
                          className="btn"
                          style={{ lineHeight: 1 }}
                        >
                          <IoChevronBack />
                        </button>
                        <h2 className="card-title mb-0">
                          Available Offers - {selectedPackage.packageName}
                        </h2>
                      </div>
                    </Card.Title>

                    <Card.Text>
                      {selectedPackage?.packageItems?.length > 0 ? (
                        selectedPackage.packageItems.map((item, index) => (
                          <div
                            key={item.packageItemId}
                            className="package-item m-sm-4 m-2"
                          >
                            <div className="package-item-content">
                              <Row>
                                <Col lg={6}>
                                  <div className="d-flex  align-items-start">
                                    <div>
                                      <span className="package-no pd">
                                        {index + 1}
                                      </span>
                                    </div>
                                    <div className="pd">
                                      {item?.packageItemDescription}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="d-flex justify-content-between kr">
                                    <div className="pd">
                                      <span className="package-title">
                                        Type:{" "}
                                      </span>
                                      {item?.itemTypeName}
                                    </div>
                                    <div className="pd">
                                      <span className="package-title">
                                        Price:{" "}
                                      </span>
                                      {item?.packageItemValue}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="m-4"
                          style={{
                            backgroundColor: "#f0f7ff",
                            padding: "10px",
                          }}
                        >
                          No Items Found for this Package.
                        </div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => navigate("/partner-profile")}
                          className="btn"
                          style={{ lineHeight: 1 }}
                        >
                          <IoChevronBack />
                        </button>
                        <h2 className="card-title mb-0">List of Packages</h2>
                      </div>
                    </Card.Title>
                    <Card.Text className="card-content">
                      {ownedPackages?.length > 0 ? (
                        ownedPackages?.map((packageObj) => (
                          <div
                            onClick={() => handlePackageClick(packageObj)}
                            key={packageObj.packageId}
                            className="m-sm-4 m-2 kppackage "
                          >
                            {packageObj?.packageName}
                          </div>
                        ))
                      ) : (
                        <div
                          className="m-4"
                          style={{
                            backgroundColor: "#f0f7ff",
                            padding: "10px",
                          }}
                        >
                          No Owned Packages Found.
                        </div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </OwnedPackagesPageContainer>
  );
};

export default OwnedPackagesPage;
