import React from "react";
import { Accordion } from "react-bootstrap";

const Accordian = ({ key, header, body }) => {
  return (
    <Accordion className="border-bottom" defaultActiveKey={0}>
      <Accordion.Item className="border-0" eventKey={key}>
        <Accordion.Header className="acco-header">{header}</Accordion.Header>
        <Accordion.Body className="acco-body">{body}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Accordian;
