import { useState, useEffect } from "react";

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isRespon, setIsRespon] = useState(window.innerWidth <= 991);

  useEffect(() => {
    function handleResizeRes() {
      setIsRespon(window.innerWidth <= 991);
    }
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleResizeRes);
    return () => {
      window.removeEventListener("resize", handleResizeRes);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return { isMobile, isRespon };
};

export default useResponsive;
