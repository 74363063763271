import { styled } from "styled-components";

export const BulkDealForBusinessPageContainer = styled.div`
  .customCard {
    /* height: 250px; */
    @media (max-width: 991px) {
      border: none;
    }
    .wwaLeft {
      order: 0;
      .top-content {
        .title {
          font-size: 30px;
          font-weight: 600;
          @media (max-width: 991px) {
            font-size: 26px;
          }
          @media (max-width: 767px) {
            font-size: 22px;
          }
          @media (max-width: 575px) {
            font-size: 18px;
          }
        }
      }
      .sub-title {
        @media (max-width: 575px) {
          font-size: 12px;
        }
      }
      @media (max-width: 991px) {
        order: 1;
        margin-top: 20px;
      }
    }
    .wwaRight {
      order: 1;
      @media (max-width: 991px) {
        order: 0;
      }
      .img-side {
        text-align: center;
      }
    }
  }

  .partner-section {
    .title-section {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      .title {
        font-size: 30px;
        font-weight: 600;
        @media (max-width: 991px) {
          font-size: 26px;
        }
        @media (max-width: 767px) {
          font-size: 22px;
        }
        @media (max-width: 575px) {
          font-size: 18px;
        }
      }
    }

    .partner-content {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .partner-icon {
        margin-right: 20px;
        @media (max-width: 991px) {
          width: 45px;
          height: 45px;
        }
        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
        }
        @media (max-width: 575px) {
          width: 36px;
          height: 36px;
        }
      }

      .content-title span {
        font-size: 22px;
        font-weight: 600;
        @media (max-width: 991px) {
          font-size: 20px;
        }
        @media (max-width: 767px) {
          font-size: 18px;
        }
        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
      .content p {
        font-size: 18px;
        margin-bottom: 0;
        @media (max-width: 991px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }
        @media (max-width: 575px) {
          font-size: 12px;
          line-height: inherit;
        }
      }
    }
  }

  .brand-section {
    .brand-title {
      font-size: 30px;
      font-weight: 600;
    }

    .brand-subtitle {
      font-size: 20px;
    }
  }

  .title-offer {
    font-size: 30px;
    font-weight: 600;
    @media (max-width: 991px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .section-no {
    background-color: #f0f7ff;
    border-radius: 12px;
    color: #024089;
    padding: 4px;
    @media (max-width: 991px) {
      font-weight: 700;
    }
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    @media (max-width: 575px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .subtitle {
    font-size: 16px;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
`;
