import { styled } from "styled-components";

export const RedeemProfileContainer = styled.div`
  .customCard {
    height: 100%;
    .card-body {
      padding: 30px;
      .profileHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaebed;
        padding-bottom: 15px;

        .imgContent {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          .profileImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            cursor: pointer;
          }
          .camera {
            position: absolute;
            bottom: -2px;
            right: 0;
            background-color: #fff;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .cameraIcon {
              background-color: #024089;
              color: #fff;
              border-radius: 50%;
              padding: 2px;
            }
          }
        }
        .profileName {
          font-weight: 600;
          margin-left: 10px;
          font-size: 24px;
        }
        .editProfileIcon {
          background-color: #024089;
          border-radius: 50%;
          color: #fff;
          width: 25px;
          height: 25px;
          padding: 4px;
        }
      }
      .profileContent {
        padding-top: 20px;
        .pc {
          display: flex;
          flex-flow: wrap;
          width: 100%;
        }
        .pct {
          flex-grow: 0;
          flex-basis: 20.66%;
          max-width: 20.66%;
        }
        .pcc {
          padding-left: 10px;
          flex-grow: 0;
          flex-basis: 78.66%;
          max-width: 78.66%;
        }
      }
      .profile1Content {
        padding-top: 20px;
        .pc {
          display: flex;
          flex-flow: wrap;
          width: 100%;
        }
        .pct {
          flex-grow: 0;
          flex-basis: 34.66%;
          max-width: 34.66%;
        }
        .pcc {
          padding-left: 10px;
          flex-grow: 0;
          flex-basis: 66.66%;
          max-width: 58.66%;
        }
      }
    }
  }

  .package-item {
    /* display: flex; */
    background-color: #f0f7ff;
    padding: 15px;
    width: 95%;

    .package-no {
      margin-right: 10px;
      border: 2px solid #1e293b;
      width: 18px;
      height: 18px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .package-item-content {
      /* display: flex;
      justify-content: space-between;
      flex-wrap: wrap; */

      .checkbox-input {
        cursor: pointer;
        margin-right: 10px;
      }
    }

    .package-title {
      font-weight: 600;
    }

    .package-redeem-count {
      border: 1px solid black;
      border-radius: 5px;
      color: white;
      padding: 5px;
      border: none;
    }
  }
  .redeembtn {
    cursor: pointer;
    :hover {
      background-color: #f0f7ff;
      border-radius: 6px;
    }
  }
  .customsmartcardImg {
    position: relative;
    text-align: center;
    .staticSmartImg {
      height: 300px;
      margin: 0 auto;
      border-radius: 17px;
      width: 100%;
    }
    .smartNo {
      position: absolute;
      bottom: 10%;
      left: 0;
      text-align: center;
      width: 100%;
      color: #fff;
      font-size: 28px;
    }
  }
  .navititle {
    font-size: 22px;
    font-weight: 600;
  }
  .sd {
    background-color: #f0f7ff;
    .customCard {
      background-color: #f0f7ff;
    }
  }

  .kpLastSection {
    h2.card-title {
      font-size: 24px;
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    .kppackage {
      background-color: #f0f7ff;
      padding: 25px;
      text-align: center;
      cursor: pointer;
      @media (max-width: 400px) {
        padding: 13px;
      }
    }
  }
  @media (min-device-width: 1199px) and (max-device-width: 1400px) {
    .customCard {
      .card-body {
        .profile1Content {
          .pct {
            flex-basis: 40.66%;
            max-width: 40.66%;
          }
          .pcc {
            flex-basis: 57.66%;
            max-width: 57.66%;
          }
        }
      }
    }
  }
  @media (min-device-width: 991px) and (max-device-width: 1200px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
        .profile1Content {
          .pct {
            flex-basis: 43.66%;
            max-width: 43.66%;
          }
          .pcc {
            flex-basis: 54.66%;
            max-width: 54.66%;
          }
        }
      }
    }
    .customsmartcardImg {
      .staticSmartImg {
        height: 237px;
      }
      .smartNo {
        font-size: 22px;
      }
    }
  }
  @media (min-device-width: 575px) and (max-device-width: 768px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
      }
    }
    .customsmartcardImg {
      .staticSmartImg {
        height: 237px;
      }
      .smartNo {
        font-size: 22px;
      }
    }
  }
  @media (min-device-width: 399px) and (max-device-width: 576px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
          .pct {
            flex-basis: 25%;
            max-width: 25%;
          }
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
      }
    }
    .customsmartcardImg {
      .staticSmartImg {
        height: 200px;
      }
      .smartNo {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 400px) {
    .customCard {
      .card-body {
        padding: 10px;
        .profileHead {
          .profileName {
            font-size: 18px;
          }
        }
        .profileContent {
          .pc {
            font-size: 12px;
          }
          .pct {
            flex-basis: 28%;
            max-width: 28%;
          }
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
        .profile1Content {
          .pc {
            font-size: 12px;
          }
          .pct {
            flex-basis: 38%;
            max-width: 38%;
          }
          .pcc {
            flex-basis: 60%;
            max-width: 60%;
          }
        }
      }
    }
    .customsmartcardImg {
      .staticSmartImg {
        height: 170px;
      }
      .smartNo {
        font-size: 22px;
      }
    }

    .navititle {
      font-size: 18px;
    }
    .redeembtn .card-body {
      padding: 7px;
    }
  }
`;
