export const SvgIcon = ({ active }) => {
  return (
    <p>
      <svg
        className="manansvg"
        viewBox="0 0 32 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.4407 4.5V4.5C27.4407 2.567 25.8737 1 23.9407 1H6C3.23858 1 1 3.23858 1 6V22C1 24.7614 3.23858 27 6 27H22.4407C25.2021 27 27.4407 24.7614 27.4407 22V14.5"
          stroke={!active ? "#1E293B" : "white"}
          stroke-width="2"
        />
        <path
          d="M8.62711 8.5L15.7457 18.5L31 7"
          stroke={!active ? "#1E293B" : "white"}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </p>
  );
};
