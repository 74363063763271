import { styled } from "styled-components";

export const AboutUsPageContainer = styled.div`
  .customCard {
    /* height: 250px; */
    @media (max-width: 991px) {
      border: none;
    }
    .wwaLeft {
      order: 0;
      .top-content {
        .title {
          font-size: 30px;
          font-weight: 600;
          @media (max-width: 575px) {
            font-size: 18px;
          }
        }
      }
      .sub-title {
        @media (max-width: 575px) {
          font-size: 12px;
        }
      }
      @media (max-width: 991px) {
        order: 1;
        margin-top: 20px;
      }
    }
    .wwaRight {
      order: 1;
      @media (max-width: 991px) {
        order: 0;
      }
      .img-side {
        text-align: center;
      }
    }
  }

  .title-offer {
    font-size: 30px;
    font-weight: 600;
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .section-no {
    background-color: #f0f7ff;
    border-radius: 12px;
    color: #024089;
    padding: 4px;
    @media (max-width: 991px) {
      font-weight: 700;
    }
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .subtitle {
    font-size: 16px;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  .missionSection {
    background-color: #f0f7ff;
    padding: 30px 0;
    .kp {
      min-height: 100%;
      .cr-img {
        text-align: center;
        img {
          width: 57px;
          height: 57px;
          @media (max-width: 992px) {
            width: 47px;
            height: 47px;
          }
          @media (max-width: 575px) {
            width: 45px;
            height: 45px;
          }
        }
      }
      .cr-title {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        @media (max-width: 992px) {
          margin-top: 18px;
          margin-bottom: 18px;
          font-size: 28px;
        }
        @media (max-width: 767px) {
          margin-top: 18px;
          margin-bottom: 18px;
          font-size: 20px;
        }
        @media (max-width: 575px) {
          font-size: 18px;
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
      .cr-content {
        p {
          font-size: 16px;
          font-weight: 400;
          @media (max-width: 575px) {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
