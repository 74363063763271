import { styled } from "styled-components";

export const TermsAndConditionPageContainer = styled.div`
  .content {
    margin: 10px 30px 10px 30px;
    padding: 15px;
    @media (max-width: 575px) {
      margin: 0;
      padding: 20px 0;
    }

    .card-title {
      font-size: 22px;
      font-weight: 600;
      color: #1e293b;
      @media (max-width: 575px) {
        font-size: 20px;
      }
      @media (max-width: 400px) {
        font-size: 18px;
      }
    }
    .tc-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: #f0f7ff;
      padding: 15px;
      @media (max-width: 400px) {
        padding: 13px;
      }

      .tc-name {
        max-width: calc(100% - 60px);
        @media (max-width: 400px) {
          font-size: 13px;
        }
      }
      .delete-btn {
        color: #024089;
        margin-right: 6px;
        cursor: pointer;
      }
      .edit-btn {
        color: #024089;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
`;
