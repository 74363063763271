import React from "react";
import OTPInput from "react-otp-input";
import useResponsive from "../../Hooks/useResponsive";

const OtpInputBox = ({ onOtpChange, otpValue, otpInputName }) => {
  const { isMobile } = useResponsive();

  return (
    <>
      <div className="otp-input-box">
        <OTPInput
          isInputSecure={false}
          onChange={onOtpChange}
          numInputs={6}
          separator={<span></span>}
          value={otpValue}
          isInputNum={true}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            border: "none",
            borderRadius: "8px",
            width: isMobile ? "40px" : "54px",
            height: isMobile ? "40px" : "54px",
            fontSize: "18px",
            fontWeight: "400",
            caretColor: "blue",
            backgroundColor: "#e5ecf4",
            marginLeft: isMobile ? "6px" : "10px",
            marginRight: isMobile ? "6px" : "10px",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
          shouldAutoFocus
        />
      </div>
      {otpValue?.length === 0 && (
        <div className="d-flex justify-content-center mt-2">
          <p className="error-message">
            {otpInputName == "SelfRedeem"
              ? "Please Enter MPIN"
              : "Please Enter Otp"}
          </p>
        </div>
      )}
    </>
  );
};

export default OtpInputBox;
