import { styled } from "styled-components";

export const PartnerListingPageContainer = styled.div`
  .categoryMainCard {
    padding: 10px;
    cursor: pointer;
    background-color: #f0f7ff;
    height: 100%;
    border: none;
    .customCenter {
      display: flex;
      justify-content: center;
      align-items: center;
      .mainImgBox {
        width: 130px;
        height: 130px;
      }
    }
    .categoryCardContent {
      padding: 0;
      .categoryTitle {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
      }
      .categorySubTitle {
        font-size: 14px;
        color: 1e293b;
        font-weight: 500;
        .sp-name {
          display: inline-block;
          background-color: #00631f73;
          border-radius: 5px;
          line-height: 20px;
          margin: 5px 6px 0 0;
          padding: 1.5px 6px;
          color: white;
        }
      }
      .categoryAddBox {
        border-top: 1px solid #b1b9c5;
        margin-top: 2px;
        padding-top: 8px;
        .locationIcon {
          font-size: 18px;
        }
        .categoryAdd {
          margin-left: 7px;
        }
      }
    }
    .categoryAddBoxMob {
      border-top: 1px solid #b1b9c5;
      margin-top: 2px;
      padding-top: 8px;
      .locationIcon {
        font-size: 18px;
      }
      .categoryAdd {
        margin-left: 7px;
      }
    }
  }
  @media (max-width: 1200px) {
    .categoryMainCard {
      .categoryCardContent {
        .categoryTitle {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .categorySubTitle {
          font-size: 14px;
          color: 1e293b;
          font-weight: 500;
          .sp-name {
            display: inline-block;
            background-color: #00631f73;
            border-radius: 5px;
            line-height: 20px;
            margin: 5px 6px 0 0;
            padding: 1.5px 6px;
            color: white;
          }
        }
        .categoryAddBox {
          border-top: 1px solid #b1b9c5;
          margin-top: 2px;
          padding-top: 8px;
          .categoryAdd {
            margin-left: 7px;
            font-size: 14px;
          }
        }
      }
      .categoryAddBoxMob {
        border-top: 1px solid #b1b9c5;
        margin-top: 2px;
        padding-top: 8px;
        .categoryAdd {
          margin-left: 7px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .categoryMainCard {
      .categoryCardContent {
        .categoryTitle {
          font-size: 18px;
          margin-bottom: 16px;
        }
        .categorySubTitle {
          font-size: 14px;
          .sp-name {
            line-height: 20px;
            margin: 5px 6px 0 0;
            padding: 1.5px 6px;
            color: white;
          }
        }
        .categoryAddBox {
          .locationIcon {
            font-size: 8px;
          }
          .categoryAdd {
            font-size: 14px;
          }
        }
      }
      .categoryAddBoxMob {
        border-top: 1px solid #b1b9c5;
        margin-top: 2px;
        padding-top: 8px;
        .categoryAdd {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 767px) {
  }
  @media (max-width: 400px) {
    .categoryMainCard {
      .customCenter {
        .mainImgBox {
          width: 130px;
          height: auto;
        }
      }
    }
  }
`;
