import Modal from "react-bootstrap/Modal";
import useResponsive from "../../Hooks/useResponsive";

const CommonModal = ({
  show,
  handleClose,
  modalTitle,
  modalBody,
  modalSize = "lg",
}) => {
  const { isMobile } = useResponsive();
  return (
    <Modal
      className="customModel"
      show={show}
      onHide={handleClose}
      centered
      size={modalSize}
    >
      <Modal.Header
        style={{
          backgroundColor: "#024089",
          color: "#fff",
        }}
        closeButton
        closeVariant="white"
      >
        <Modal.Title style={{ fontSize: isMobile ? "18px" : "20px" }}>
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
    </Modal>
  );
};

export default CommonModal;
