import { styled } from "styled-components";

export const PartnerDetailsPageContainer = styled.div`
  .customCard {
    height: 100%;
    .card-body {
      padding: 30px;
      .profileHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaebed;
        padding-bottom: 15px;

        .imgContent {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          .profileImg {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
        .profileName {
          font-weight: 600;
          margin-left: 20px;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .profileContent {
        padding-top: 20px;
        border-bottom: 1px solid #eaebed;
        padding-bottom: 15px;
        .info {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .faqContent {
        padding-top: 20px;
      }
    }
  }

  .offer-card {
    padding: 20px;
    @media (max-width: 400px) {
      padding: 0;
    }
    .offers {
      .offers-title {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .tac-card {
    padding: 20px;
    @media (max-width: 400px) {
      padding: 0;
    }
    .tac {
      .tac-title {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .rd-card {
    border: none;
    .redeem-card {
      background-color: #f0f7ff;
      border-radius: 5px;
      padding: 20px;
      .redeem-btn {
        display: flex;
        justify-content: center;
        height: 40px;
        button {
          width: 40%;
          color: white;
          background-color: #f9841a;
          border: none;
          border-radius: 5px;
          font-size: 18px;
        }
      }
    }
  }

  @media (min-device-width: 991px) and (max-device-width: 1200px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
        }
      }
    }
    .rd-card {
      margin-top: 10px;
    }
  }
  @media (min-device-width: 575px) and (max-device-width: 768px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
        }
      }
    }
    .rd-card {
      margin-top: 10px;
    }
  }
  @media (min-device-width: 399px) and (max-device-width: 576px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
        }
      }
    }

    .rd-card {
      margin-top: 10px;
    }
  }
  @media (max-width: 400px) {
    .customCard {
      .card-body {
        padding: 10px;
        .profileHead {
          .profileName {
            font-size: 18px;
          }
        }
        .profileContent {
          .info {
            font-size: 15px;
          }
        }
      }
    }

    .rd-card {
      margin-top: 10px;
      .redeem-card {
        .redeem-btn {
          display: flex;
          justify-content: center;

          button {
            min-width: 150px;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
