import { styled } from "styled-components";

export const ModalContainer = styled.div`
  .labelName {
    font-size: 16px;
    font-weight: 400;
    color: #024089;
  }
  input {
    width: 100%;
    border: none;
    background: #f0f7ff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  input:focus {
    border: none;
    outline: none;
  }

  .modelProfileImg {
    width: 255px;
    height: 255px;
    border-radius: 50%;
    margin: 0 auto;
  }
  .modelProfileImg img {
    width: 255px;
    height: 255px;
    border-radius: 50%;
    margin: 0 auto;
  }

  .headselect {
    margin-top: 10px;
    height: 43px;
  }
`;
