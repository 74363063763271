import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ element, allowedRole }) => {
  const role = localStorage.getItem("role");

  const isAuthenticated = allowedRole.includes(role);

  return isAuthenticated ? (
    element
  ) : (
    <Navigate
      to={
        role === "partner"
          ? "/login"
          : role === "user"
          ? "/partnerRegistration"
          : "/login"
      }
    />
  );
};

export default ProtectedRoutes;
