import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const generalSettingSlice = createSlice({
  name: "generalSetting",
  initialState,
  reducers: {
    setGeneralSetting: (_, action) => {
      return action.payload;
    },
  },
});

export const { setGeneralSetting } = generalSettingSlice.actions;

export default generalSettingSlice.reducer;
