import React from "react";
import { FaSpinner } from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Loader = () => {
  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 420px)",
        }}
      >
        <FaSpinner
          className="fa-spin"
          style={{
            fontSize: "48px",
            animation: "spin 1s linear infinite",
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Loader;
