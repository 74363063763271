import { styled } from "styled-components";

export const ContactUsPageContainer = styled.div`
  .customCard {
    height: auto;
    .contact-left-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 30px;
        font-weight: 600;
        color: #000000;

        @media (max-width: 991px) {
          font-size: 26px;
        }

        @media (max-width: 767px) {
          font-size: 24px;
        }

        @media (max-width: 575px) {
          font-size: 20px;
        }
      }
    }

    .sub-title {
      .sub-title-content {
        font-size: 20px;
        color: #1e293b;

        @media (max-width: 991px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }

  .customCard2 {
    .head-right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-content {
        .info {
          font-size: 30px;
          font-weight: 600;
          color: #1e293b;

          @media (max-width: 991px) {
            font-size: 26px;
          }

          @media (max-width: 767px) {
            font-size: 24px;
          }

          @media (max-width: 575px) {
            font-size: 20px;
          }
        }
        .locationKp{
          @media (max-width: 676px) {
            margin-left: 4px;
          }
          @media (max-width: 400px) {
            margin-left: 12px;
          }
        }
      }
    }
  }
`;
