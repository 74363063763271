import { useState } from "react";
import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";

export const usePartnerDetails = () => {
  const [partnerDetails, setPartnerDetails] = useState({});

  const getPartnerDetails = async (partnerId) => {
    const partnerRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/${partnerId}`,
      "GET",
      null
    );

    if (partnerRes?.data?.status) {
      setPartnerDetails(partnerRes?.data?.response);
    } else {
      setPartnerDetails(null);
    }
  };

  return { getPartnerDetails, partnerDetails };
};
