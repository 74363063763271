import { combineReducers } from "@reduxjs/toolkit";
import userDataReducer from "./User/UserSlice";
import partnerDataReducer from "./Partner/PartnerSlice";
import partnerListReducer from "./Partner/PartnerListSlice";
import generalSettingReducer from "./GeneralSetting";

export const rootReducer = combineReducers({
  userData: userDataReducer,
  partnerData: partnerDataReducer,
  partnerList: partnerListReducer,
  generalSetting: generalSettingReducer,
});
