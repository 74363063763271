import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { rootReducer } from "./configureStore";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific action types
        ignoredActions: ["persist/PERSIST"],
        // Ignore specific action fields
        ignoredActionPaths: ["register", "rehydrate"],
        // Ignore specific state paths
        ignoredPaths: ["some.nested.path"],
      },
    }),
});

export const persistor = persistStore(store);
