import React from "react";

import { CompanyLogoContainer } from "./CompanyLogoContainer";

const CompanyLogo = ({ companyLogo, className = "", ...props }) => {
  return (
    <CompanyLogoContainer>
      <img
        src={companyLogo}
        alt="Company Logo"
        className={className}
        {...props}
      />
    </CompanyLogoContainer>
  );
};

export default CompanyLogo;
