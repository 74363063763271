import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { PartnerListingPageContainer } from "./PartnerListingPageContainer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PartnerListCard from "../../components/PartnerListCard/PartnerListCard";

const PartnerListingPage = () => {
  const navigate = useNavigate();
  const partnerList = useSelector((state) => state.partnerList);

  const handleCardClick = (partnerObj) => {
    const partnerName = partnerObj?.partnerName
      .split(" ")
      .join("_")
      .toLowerCase();
    navigate(`/partner/${partnerName}/${partnerObj?.partnerId}`);
  };

  return (
    <PartnerListingPageContainer>
      <Header />
      <div className="allContent">
        <PartnerListCard
          partners={partnerList?.partners}
          handleCardClick={handleCardClick}
        />
      </div>
      <Footer />
    </PartnerListingPageContainer>
  );
};

export default PartnerListingPage;
