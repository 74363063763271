import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";
import { useDispatch } from "react-redux";
import { setGeneralSetting } from "../store/GeneralSetting";
import { toastMessage } from "../utils/toastUtils";

export const useGeneralSetting = () => {
  const dispatch = useDispatch();

  const getGeneralSetting = async () => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/generalSetting/`,
      "GET",
      null
    );

    if (res?.data?.status) {
      dispatch(setGeneralSetting(res?.data?.response));
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  return { getGeneralSetting };
};
