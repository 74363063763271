import { styled } from "styled-components";

export const LoginPageContainer = styled.div`
  background-color: ${(props) => (props.isRespon ? "#ffffff" : "#f0f7ff")};
  .login-parent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .partner-link {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    p {
      margin-right: 5px;
    }
  }
  @media (max-width: 991px) {
    height: 100svh;
  }

  .otp-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .otp-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
