import { styled } from "styled-components";

export const OwnedPackagesPageContainer = styled.div`
  .content {
    margin: 10px 30px 10px 30px;
    padding: 15px;
    @media (max-width: 575px) {
      margin: 0;
      padding: 20px 0;
    }
    .card-title {
      font-size: 22px;
      font-weight: 600;
      color: #1e293b;
      @media (max-width: 400px) {
        font-size: 18px;
      }
    }

    .card-content {
      display: flex;
      flex-wrap: wrap;
      .kppackage {
        background-color: #f0f7ff;
        padding: 25px;
        text-align: center;
        cursor: pointer;
        @media (max-width: 400px) {
          padding: 13px;
        }
      }
    }

    .package-item {
      /* display: flex; */
      background-color: #f0f7ff;
      padding: 20px;
      width: 95%;

      .package-no {
        margin-right: 10px;
        margin-top: 4px;
        border: 1px solid #1e293b;
        width: 18px;
        height: 18px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 400px) {
          margin-top: 4px;
          width: 17px;
          height: 17px;
        }
      }
      .package-item-content {
        /* display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
        .kr {
          @media (max-width: 992px) {
            padding-left: 25px;
          }
        }
        .pd {
          @media (max-width: 400px) {
            font-size: 13px;
          }
        }
      }

      .package-title {
        font-weight: 600;
      }
    }
  }
`;
