import React, { useEffect, useState } from "react";
import { RedeemPackagesPageContainer } from "./RedeemPackagesPageContainer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { formatDateString } from "../../utils/DateFormat";
import Loader from "../../components/Loader/Loader";

const RedeemPackagesPage = () => {
  const userId = localStorage.getItem("userId");

  const [redeemPackages, setRedeemPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);

  useEffect(() => {
    const getRedeemPackages = async () => {
      setLoadingPackages(true);
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/redemptions/fetchby/${userId}`,
        "GET",
        null
      );
      if (res?.data?.status) {
        setLoadingPackages(false);
        setRedeemPackages(res?.data?.response?.redeemptionLogs);
      } else {
        setRedeemPackages([]);
        setLoadingPackages(false);
      }
    };

    getRedeemPackages();
  }, []);

  if (loadingPackages) {
    return <Loader />;
  }

  return (
    <RedeemPackagesPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col xs={12}>
              <div>
                <Card className="mainCardSection">
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center justify-content-between">
                      <div>
                        <h2 className="card-title">Redeemed Packages</h2>
                      </div>
                    </Card.Title>
                    <Card.Text>
                      {redeemPackages?.length > 0 ? (
                        redeemPackages?.map((redeemObj) => {
                          return (
                            <div className="profile1Content mb-lg-3 mb-2">
                              <div className="mobileHeader">
                                Redeemed Packages
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Package Name</span>:
                                <span
                                  className="pcc"
                                  style={{ color: "#024089", fontWeight: 700 }}
                                >
                                  {redeemObj?.packageName}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Description</span>:
                                <span className="pcc">
                                  {redeemObj?.packageItemDescription}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Partner Name</span>:
                                <span
                                  className="pcc"
                                  style={{ color: "#f9841a", fontWeight: 700 }}
                                >
                                  {redeemObj?.partnerName}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Partner Mobile</span>
                                :
                                <span className="pcc">
                                  {redeemObj?.partnerMobile}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Type</span>:
                                <span className="pcc">
                                  {redeemObj?.itemTypeName}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Redeem Count</span>:
                                <span className="pcc">
                                  {redeemObj?.redeemCount === -1
                                    ? "Unlimited"
                                    : redeemObj?.redeemCount}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Value</span>:
                                <span className="pcc">
                                  {redeemObj?.packageItemValue}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Time</span>:
                                <span className="pcc">
                                  {formatDateString(redeemObj?.modifiedOn)}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="m-4"
                          style={{
                            backgroundColor: "#f0f7ff",
                            padding: "10px",
                          }}
                        >
                          No Redeem Packages Found.
                        </div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </RedeemPackagesPageContainer>
  );
};

export default RedeemPackagesPage;
