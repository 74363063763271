import React, { useEffect, useState } from "react";
import { FooterContainer } from "./FooterContainer";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import CompanyLogo from "../Logo/CompanyLogo";
import logo from "../../assets/images/company_logo.png";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import appStore from "../../assets/images/app_store.png";
import googleStore from "../../assets/images/google_store.png";
import { Link, useNavigate } from "react-router-dom";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import { swalUtils } from "../../utils/swalUtils";
import { toastMessage } from "../../utils/toastUtils";
import { useDispatch } from "react-redux";
import { resetPartnerData } from "../../store/Partner/PartnerSlice";
import { resetUserData } from "../../store/User/UserSlice";

const Footer = () => {
  const [socialMediaList, setSocialMediaList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getSocialMediaList = async () => {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/socialMedia/`,
        "GET",
        null
      );

      if (res?.data?.status) {
        setSocialMediaList(res?.data?.response);
      }
    };

    getSocialMediaList();
  }, []);

  const handleUnSubscribe = async () => {
    const res = await swalUtils(
      "Are you sure?",
      "You will be unsubscribed!",
      "Cancel",
      "Yes, Unsubscribe!"
    );

    if (res.dismiss === "cancel" && res.isDismissed) {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/user/deactivete/${
          localStorage.getItem("userId")
            ? localStorage.getItem("userId")
            : localStorage.getItem("partnerId")
        }`,
        "DELETE",
        null
      );

      if (res?.data?.status) {
        toastMessage("You have been unsubscribed!", "success");
        dispatch(resetPartnerData());
        dispatch(resetUserData());
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
        localStorage.removeItem("partnerId");
        navigate("/login");
      }
    }
  };

  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col lg={3}>
            <Row>
              <Col lg={12} xs={6}>
                <div>
                  <CompanyLogo
                    companyLogo={logo}
                    className="inner-logo img-fluid"
                  />
                </div>
              </Col>
              <Col lg={12} xs={6} className="mt-lg-5  mt-0">
                <div>
                  <span className="footerTitle">Follow us on</span>
                  <div className="footerSocialIcon mt-2">
                    {socialMediaList?.map((sc) => {
                      return (
                        <a key={sc.socialMediaId} href={sc.link}>
                          {sc.type === "Telegram" ? (
                            <FaTelegramPlane className="footerIcon" />
                          ) : sc.type === "linkedin" ? (
                            <FaLinkedinIn className="footerIcon" />
                          ) : sc.type === "Instagram" ? (
                            <FaInstagram className="footerIcon" />
                          ) : (
                            <FaFacebookF className="footerIcon" />
                          )}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={9} className="mt-lg-0 mt-2">
            <Row>
              <Col lg={3} xs={6}>
                <div>
                  <span className="footerTitle">Business</span>
                  <ul className="footerList">
                    {/* <li>
                      <a href="/#franchise">For Franchise</a>
                      <Link to={"#franchise"}>For Franchise</Link>
                    </li> */}
                    <li>
                      <Link to="/partnerforbusiness">Partner</Link>
                    </li>
                    <li>
                      <Link to="/bulkdealforbusiness">Bulk Deal</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} xs={6}>
                <div>
                  <span className="footerTitle">Support</span>
                  <ul className="footerList">
                    <li>
                      <Link to="/AboutUs">About Us</Link>
                    </li>
                    <li>
                      <Link to="/ContactUs">Contact Us</Link>
                    </li>
                    {/* <li>
                      <Link>FAQs</Link>
                    </li> */}
                    {/* <li>
                      <Link>Blog</Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col lg={3} xs={6}>
                <div>
                  <span className="footerTitle">Legal</span>
                  <ul className="footerList">
                    <li>
                      <Link to="/TermsAndCondition">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                    {localStorage.getItem("role") === "user" && (
                      <li onClick={handleUnSubscribe}>
                        <Link>Unsubscribe</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col lg={3} xs={6}>
                <div>
                  <span className="footerTitle">Download our app</span>
                  <Row className="mt-2 footerstore">
                    <Col sm={6}>
                      <a
                        style={{ cursor: "pointer" }}
                        href="https://apps.apple.com/in/app/smaart-card/id6478766537"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="img-fluid"
                          src={appStore}
                          alt="app_store_img"
                        />
                      </a>
                    </Col>
                    <Col sm={6} className="mt-sm-0 mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        href="https://play.google.com/store/apps/details?id=com.vasy.smaartcard"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="img-fluid"
                          src={googleStore}
                          alt="google_store_img"
                        />
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col className="footerLast">
            <span>
              Product by JRD Card LLP and Crafted by VasyERP with ❤️ in
              Ahmedabad, India
            </span>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
