import { styled } from "styled-components";
import urlImg from "../../assets/images/toggle.png";
import cancelImg from "../../assets/images/kp.png";

export const HeaderContainer = styled.div`
  width: 100%;
  /* height: 132px; */
  background-color: #024089;
  position: sticky;
  top: 0;
  z-index: 1;
  .search-input {
    width: 100%;
    height: 35px;
    padding: 8px 35px 8px 9px;
    border-radius: 6px;
    border: none;
    @media (max-width: 575px) {
      font-size: 14px;
      height: 30px;
    }
  }
  .profile-icon {
    color: #fff;
    .profileName {
      margin-left: 10px;
      text-wrap: wrap;
    }
  }
  .nav-headerKp {
    color: #fff;
    margin-right: 20px;
    cursor: pointer;
  }
  .nav-headerKp.active {
    font-weight: 900;
  }
  .headselect {
    padding: 12px 10px;
  }
  .headuserIcon {
    font-size: 35px;
  }
  .profile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .customRow {
    display: flex;
    justify-content: right;
    align-items: center;
    .inner-logo {
      height: 38px;
      @media (max-width: 991px) {
        height: 30px;
      }
    }
  }
  .mobileHeader {
    background-color: #024089 !important;
  }

  .kp {
    .active2.nav-link {
      background: #000 !important;
    }
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    background-color: red !important;
  }
  .navbar-toggler-icon {
    background-image: url(${urlImg});
    @media (max-width: 400px) {
      width: 20px;
      height: 20px;
    }
  }

  .headbox {
    .headselect {
      /* padding: 7px; */
      color: #7c7c7c;
      height: 37px;
      padding: 0 8px;
      @media (max-width: 575px) {
        font-size: 14px;
        height: 30px;
      }
    }
    .search {
      position: relative;

      .search-icon {
        position: absolute;
        top: 4px;
        /* left: 95%; */
        right: 10px;
        bottom: 0;
        color: #838383;
        @media (max-width: 992px) {
          top: 1px;
        }
      }

      /* height: 500px; */
      .search-result {
        position: absolute;
        top: 36px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 500px;
        border-radius: 5px;
        overflow-y: auto;
        @media (max-width: 575px) {
          top: 31px;
        }

        .no-result {
          padding: 10px;
          text-align: center;
          color: #636363;
          background-color: #fff;
        }

        .result-list {
          cursor: pointer;
          display: flex;
          background-color: #ffff;
          padding: 10px;
          border-bottom: 1px solid #f3f4f6;
          @media (max-width: 991px) {
            padding: 6px;
          }
          @media (max-width: 757px) {
            padding: 4px;
          }
          &:hover {
            background-color: #f0f0f0;
          }

          &:active {
            background-color: #d0d0d0;
          }

          .result-details {
            margin-left: 10px;
            width: 100%;

            span {
              @media (max-width: 757px) {
                font-size: 14px;
              }

              @media (max-width: 400px) {
                font-size: 13px;
              }
            }

            .sub-details {
              width: 100%;
              .type-data {
                margin-right: 20px;
                width: 50%;

                @media (max-width: 757px) {
                  font-size: 14px;
                }

                @media (max-width: 400px) {
                  font-size: 13px;
                }
              }

              .city-data {
                width: 50%;
                @media (max-width: 757px) {
                  font-size: 14px;
                }

                @media (max-width: 400px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .navbar-toggler {
      box-shadow: none;
      padding: 0;
      border: none;
    }

    .headbox {
      order: 5;
    }
    .headuserIcon {
      font-size: 35px;
      @media (max-width: 991px) {
        font-size: 30px;
      }
    }
  }
`;
