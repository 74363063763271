import React, { useState } from "react";
import { OffersContainer } from "./OffersContainer";
import { useGetOffers } from "../../Hooks/useGetOffers";
import usePartnerList from "../../Hooks/usePartnerList";

const OffersList = () => {
  const { offers } = useGetOffers();

  const cityName = localStorage.getItem("partnerCity");

  const { getPartnerList } = usePartnerList();

  const [activeOffer, setActiveOffer] = useState(
    parseInt(localStorage.getItem("offerId") || 0)
  );

  const handleOfferClick = (offerId) => {
    if (activeOffer === offerId) {
      setActiveOffer(0);
      localStorage.setItem("offerId", 0);
      getPartnerList(0, 100, cityName || "", 0, 0);
    } else {
      setActiveOffer(offerId);
      localStorage.setItem("offerId", offerId);
      getPartnerList(0, 100, cityName || "", 0, offerId);
    }
  };

  return (
    <OffersContainer>
      {offers?.length > 0 &&
        offers.map(({ offerId, offerName }) => (
          <span
            key={offerId}
            className={`filter-offer`}
            onClick={() => handleOfferClick(offerId)}
            style={{
              backgroundColor: activeOffer !== offerId ? "#fff" : "#024089",

              borderRadius: "30px",
              width: "auto",
              border: activeOffer !== offerId && "2px solid #024089",
              color: activeOffer !== offerId ? "#024089" : "#fff",
            }}
          >
            {offerName}
          </span>
        ))}
    </OffersContainer>
  );
};

export default OffersList;
