import React, { useEffect, useState } from "react";
import { UserRegistrationContainer } from "./UserRegistrationContainer";
import useResponsive from "../../Hooks/useResponsive";
import CompanyLogo from "../../components/Logo/CompanyLogo";
import logo from "../../assets/images/logo.png";
import Input from "../../components/Input/Input";
import Footer from "../../components/Footer/Footer";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastMessage } from "../../utils/toastUtils";
import OtpInputBox from "../../components/OtpInputBox/OtpInputBox";
import { Link, useNavigate } from "react-router-dom";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { useDispatch } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { usePartnerState } from "../../Hooks/usePartnerState";
import { setUserData } from "../../store/User/UserSlice";
import { usePartnerCity } from "../../Hooks/usePartnerCity";
import { IoChevronBack } from "react-icons/io5";
import { useCity } from "../../Hooks/useCity";

const UserRegistration = () => {
  const { isMobile, isRespon } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stateValue, setStateValue] = useState("24");
  const [cityList, setCityList] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [error, setError] = useState({ state: "", city: "" });
  const { state } = usePartnerState();

  const { cityList: city } = useCity();

  const onStateChange = async (e) => {
    const value = e.target.value;
    setStateValue(e.target.value);
    setError((prev) => ({ ...prev, state: "" }));

    const cityRes = await axiosFunction(
      `${smaartCardUrl}/location/api/city/${value}`,
      "GET",
      null
    );

    setCityList(cityRes?.data);
  };

  const onCityChange = (e) => {
    setCityValue(e.target.value);
    setError((prev) => ({ ...prev, city: "" }));
  };

  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");

  const [otpValue, setOtpValue] = useState("");

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const [otpScreen, setOtpScreenPage] = useState(false);

  const alreadyAddedMobNo = localStorage.getItem("mobNo");

  const registrationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Please Enter Your Name")
      .matches(/^[a-z A-Z-]+$/, "Enter Valid Name")
      .min(2, "Name should be 2 to 30 characters")
      .max(30, "Name Should be less then 30 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      mobileNo: alreadyAddedMobNo,
      name: "",
    },
    resolver: yupResolver(registrationSchema),
    mode: "onChange",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { state: "", city: "" };

    if (!stateValue) {
      newErrors.state = "State is required";
      isValid = false;
    }
    if (!cityValue) {
      newErrors.city = "City is required";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const onSubmit = async (data) => {
    if (!validateForm()) return;
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/otp/user/send`,
      "POST",
      {
        name: data.name,
        phone: data.mobileNo,
        state: "24",
        city: cityValue,
      }
    );

    if (res?.data?.status) {
      setOtpScreenPage(true);
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleResendLink = async () => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/otp/send`,
      "POST",
      { phone: getValues("mobileNo") }
    );

    if (res?.data?.status) {
      setOtpValue("");
      toastMessage(res?.data?.message, "success");
    } else {
      return false;
    }
  };

  const handleSubmitOtp = async () => {
    const verifyUserRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/verifyOtpRegistration`,
      "POST",
      {
        name: getValues("name"),
        otp: otpValue,
        phone: getValues("mobileNo"),
        city: cityValue,
      }
    );

    if (verifyUserRes?.data?.status) {
      navigate("/");

      dispatch(setUserData(verifyUserRes?.data?.response));
      localStorage.setItem("userId", verifyUserRes?.data?.response?.patientId);
      localStorage.setItem("role", "user");
      localStorage.setItem("userCity", verifyUserRes?.data?.response?.city);
      localStorage.removeItem("partnerId");
    } else {
      toastMessage(verifyUserRes?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (userId && role === "user") {
      navigate("/");
    }
  }, [navigate, userId, role]);

  if (userId && role === "user") {
    return null;
  }

  return (
    <UserRegistrationContainer isRespon={isRespon}>
      {isMobile && (
        <button onClick={() => navigate("/login")} className="btn">
          <IoChevronBack />
        </button>
      )}
      <div className="partner-parent">
        <div className="login-modal">
          <div className="login-header">
            {!otpScreen ? (
              <>
                <CompanyLogo companyLogo={logo} className="logo" />

                <h1 className="title-name">User Registration</h1>

                <div className="main-login-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      label={"Name"}
                      type={"text"}
                      placeholder={"Enter Name"}
                      control={control}
                      register={register}
                      nameField={"name"}
                      errors={errors}
                    />
                    &nbsp;
                    <Input
                      label={"Mobile No."}
                      type={"text"}
                      placeholder={"Enter Mobile No."}
                      control={control}
                      register={register}
                      nameField={"mobileNo"}
                      errors={errors}
                      disabled
                    />
                    &nbsp;
                    <Row>
                      <Col lg={6} xs={6}>
                        <Form.Select
                          aria-label="Default select example"
                          className="headselect"
                          value={stateValue}
                          onChange={onStateChange}
                          disabled
                        >
                          <option>Select State</option>
                          {state?.map((st) => {
                            return (
                              <option key={st.stateId} value={st.stateCode}>
                                {st.stateName}
                              </option>
                            );
                          })}
                        </Form.Select>
                        {error.state && (
                          <p className="error-message">{error.state}</p>
                        )}
                      </Col>
                      <Col lg={6} xs={6}>
                        <Form.Select
                          aria-label="Default select example"
                          className="headselect"
                          value={cityValue}
                          onChange={onCityChange}
                        >
                          <option>Select City</option>
                          {city?.map((ct) => {
                            return (
                              <option key={ct.cityId} value={ct.cityName}>
                                {ct.cityName}
                              </option>
                            );
                          })}
                        </Form.Select>
                        {error.city && (
                          <p className="error-message">{error.city}</p>
                        )}
                      </Col>
                    </Row>
                    <button type="submit" className="otp-btn">
                      Register
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="otp-modal">
                  <div className="otp-title">
                    <h2 className="title-name">Account Confirmation</h2>
                    <div>
                      <span>
                        We just sent you an confirmation code on your phone.
                      </span>
                      <span>Please enter the code to verify your account.</span>
                    </div>
                  </div>

                  <OtpInputBox
                    onOtpChange={handleOtpChange}
                    otpValue={otpValue}
                  />
                  <div className="main-login-container">
                    <button onClick={handleSubmitOtp} className="otp-btn">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <p>Didn't receive the code? </p>
                  <Link onClick={handleResendLink}>Resend</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </UserRegistrationContainer>
  );
};

export default UserRegistration;
