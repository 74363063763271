import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import defaultPhoto from "../../assets/images/imagePlaceholder.png";
import { FaLocationDot } from "react-icons/fa6";
import OffersList from "../OfferList/Offers";

const PartnerListCard = ({ partners, handleCardClick }) => {
  return (
    <Container>
      <OffersList />
      <Row>
        {partners?.length > 0 ? (
          partners?.map((partnerObj) => {
            return (
              <Col md={6} className="mt-2">
                <Card
                  onClick={() => handleCardClick(partnerObj)}
                  className="categoryMainCard"
                >
                  <Row>
                    <Col md={4} xs={4} className="customCenter">
                      <div className="mainImgBox">
                        <img
                          className="img-fluid"
                          src={partnerObj?.partnerPhoto || defaultPhoto}
                          alt="google_store_img"
                        />
                      </div>
                    </Col>
                    <Col md={8} xs={8}>
                      <Card.Body className="categoryCardContent">
                        <Card.Title className="categoryTitle">
                          {partnerObj?.partnerName}
                        </Card.Title>
                        <Card.Subtitle className="mb-2 categorySubTitle">
                          <div className="mb-1">{partnerObj?.category}</div>
                          <strong style={{ margin: "0 5px 0 0" }}>
                            Specialization:
                          </strong>
                          {partnerObj?.specialization?.length > 0
                            ? partnerObj?.specialization?.map((spc) => (
                                <span className="sp-name">{spc}</span>
                              ))
                            : "--"}
                        </Card.Subtitle>
                        <Card.Text className="categoryAddBox d-lg-block d-none">
                          <FaLocationDot className="locationIcon" />
                          <span className="categoryAdd">
                            {partnerObj?.partnerAddress}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Col>
                    <Col xs={12} className="d-lg-none d-block">
                      <div className="categoryAddBoxMob">
                        <FaLocationDot className="locationIcon" />
                        <span className="categoryAdd">
                          {partnerObj?.partnerAddress}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#f0f7ff",
              borderRadius: "5px",
              lineHeight: "20px",
              padding: "20px",
              color: "#024089",
              marginTop: "20px",
            }}
          >
            No Partner Found
          </div>
        )}
      </Row>
    </Container>
  );
};

export default PartnerListCard;
