import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const partnerListSlice = createSlice({
  name: "partnerList",
  initialState,
  reducers: {
    setPartnerList: (_, action) => {
      return action.payload;
    },
    resetPartnerList: () => {
      return initialState;
    },
  },
});

export const { setPartnerList, resetPartnerList } = partnerListSlice.actions;

export default partnerListSlice.reducer;
