import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PartnerRegistrationContainer } from "./PartnerRegistrationContainer";
import CompanyLogo from "../../components/Logo/CompanyLogo";
import logo from "../../assets/images/logo.png";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/Input/Input";
import useResponsive from "../../Hooks/useResponsive";
import { useForm } from "react-hook-form";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { toastMessage } from "../../utils/toastUtils";
import { useDispatch } from "react-redux";
import { setPartnerData } from "../../store/Partner/PartnerSlice";

const PartnerRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const partnerId = localStorage.getItem("partnerId");
  const role = localStorage.getItem("role");

  const { isMobile, isRespon } = useResponsive();
  const emailRegex =
    /^(?!.*[^A-Za-z0-9]{2})[A-Za-z0-9]\.*(?=[A-Za-z][a-zA-Z0-9@._-]{5,50}$)[a-zA-Z0-9._-]{1,40}@(?:(?=[a-zA-Z][a-zA-Z0-9-]{1,15}\.)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*\.){1,2}[a-zA-Z]{2,6}$/;

  const partnerLoginSchema = yup.object().shape({
    email: yup
      .string()
      .required("Please Enter Email")
      .matches(emailRegex, "Enter Valid Email"),
    password: yup.string().required("Please Enter Password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(partnerLoginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/auth/partner/signin`,
      "POST",
      { email: data.email, password: data.password }
    );

    if (res?.data?.status) {
      dispatch(setPartnerData(res?.data?.response?.partnerDetails));
      localStorage.setItem("isCustomizedPartner", res?.data?.response?.isCustomized);
      localStorage.setItem("isManagePointdPartner", res?.data?.response?.isManagePoint);
      localStorage.setItem("role", "partner");
      localStorage.setItem(
        "partnerId",
        res?.data?.response?.partnerDetails?.partnerId
      );
      localStorage.removeItem("userId");
      localStorage.setItem(
        "isCustomizedForDeduction",
        res?.data?.response?.isCustomized
      );
      navigate("/partner-profile");
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (partnerId && role === "partner") {
      navigate("/partner-profile");
    }
  }, [navigate, partnerId, role]);

  if (partnerId && role === "partner") {
    return null;
  }

  return (
    <PartnerRegistrationContainer isRespon={isRespon}>
      <div className="partner-parent">
        <div className="login-modal">
          <div className="login-header">
            <div className="d-flex justify-content-center">
              <CompanyLogo companyLogo={logo} />
            </div>
            <h1 className="title-name">Partner Login</h1>

            <div className="main-login-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  label={"Email"}
                  type={"text"}
                  placeholder={"Enter Email"}
                  nameField={"email"}
                  register={register}
                  control={control}
                  errors={errors}
                />
                &nbsp;
                <Input
                  label={"Password"}
                  type={"password"}
                  placeholder={"Enter Password"}
                  nameField={"password"}
                  register={register}
                  control={control}
                  errors={errors}
                />
                <button type="submit" className="otp-btn">
                  Login
                </button>
              </form>
            </div>

            <div className="password-link">
              <p>Forgot Password? </p>
              <Link to={"/forgetpassword"}>Click Here</Link>
            </div>

            <div className="user-link">
              <p>Are You a User? </p>
              <Link to={"/login"}>Click Here</Link>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </PartnerRegistrationContainer>
  );
};

export default PartnerRegistration;
