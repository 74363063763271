import { styled } from "styled-components";

export const CmsPageContainer = styled.div`
  .cms-title {
    border-bottom: 1px solid #959595;

    .title {
      font-size: 30px;

      @media (max-width: 575px) {
        font-size: 24px;
      }
    }

    .body {
      font-size: 30px;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
`;
