import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header/Header";
import useResponsive from "../../Hooks/useResponsive";
import Input from "../../components/Input/Input";
import { RedeemDashboardContainer } from "./RedeemDashboardContainer";
import OtpInputBox from "../../components/OtpInputBox/OtpInputBox";
import Footer from "../../components/Footer/Footer";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { toastMessage } from "../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/User/UserSlice";
import { IoChevronBack } from "react-icons/io5";
import AccessDeniedPage from "../AccessDeniedPage/AccessDeniedPage";

const RedeemDashboard = () => {
  const { isMobile, isRespon } = useResponsive();
  const partnerData = useSelector((state) => state.partnerData);
  const userData = useSelector((state) => state.userData);
  const redeemPartnerId = localStorage.getItem("redeemPartnerId");

  const role = localStorage.getItem("role");

  const [state, setState] = useState("cardNo");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otpValue, setOtpValue] = useState(
    role === "user" ? userData?.mpin : ""
  );

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const cardNumberSchema = yup.object().shape({
    cardNo: yup.string().required("Please Enter Card Number"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      cardNo: role === "user" ? userData?.cardNumber : "",
    },
    resolver: yupResolver(cardNumberSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const isDeduction =
      localStorage.getItem("CardNumberVerified") === "Redeem" ? 0 : 1;
    const partnerId =
      localStorage.getItem("CardNumberVerified") !== "Redeem"
        ? partnerData.partnerId
        : 0;
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/master/subscriptionCards/${data.cardNo}?isDeduction=${isDeduction}&partnerId=${partnerId}`,
      "GET",
      null
    );

    if (res?.data?.status) {
      setState("otp");
      localStorage.setItem(
        "redeemUserMobNo",
        res?.data?.response?.patient?.phone
      );
    } else {
      setState("cardNo");
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleSubmitOtp = async () => {
    const otpRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/verifyMpin?phone=${localStorage.getItem(
        "redeemUserMobNo"
      )}&Mpin=${otpValue}&partnerId=${localStorage.getItem("redeemPartnerId")}`,
      "GET",
      null
    );

    if (otpRes?.data?.status) {
      toastMessage(otpRes?.data?.message, "success");
      if (localStorage.getItem("CardNumberVerified") === "DeductionPoints") {
        localStorage.setItem("CardNumberVerified", "DeductionPoints");
      } else {
        localStorage.setItem("CardNumberVerified", "Redeem");
      }
      // localStorage.setItem("CardNumberVerified","Redeem")

      localStorage.setItem(
        "redeemPartnerId",
        otpRes?.data?.response?.partnerId
        // partnerData?.partnerId
      );
      navigate("/redeemDashboard-profile");

      dispatch(setUserData(otpRes?.data?.response));
    } else {
      setState("otp");
      toastMessage(otpRes?.data?.message, "error");
    }
  };

  if (!redeemPartnerId) {
    return <AccessDeniedPage />;
  }

  return (
    <RedeemDashboardContainer isRespon={isRespon}>
      {!isMobile && <Header />}

      <div className="login-parent">
        {isMobile && (
          <button onClick={() => navigate("/")} className="btn backBtn">
            <IoChevronBack />
          </button>
        )}
        <div className="login-modal">
          <div className="login-header">
            {state === "cardNo" ? (
              <>
                <h2 className="title-name">Verify User</h2>

                <div className="main-login-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      label={"Card Number"}
                      type={"number"}
                      placeholder={"Enter Card Number"}
                      control={control}
                      register={register}
                      nameField={"cardNo"}
                      errors={errors}
                    />
                    <button className="otp-btn">Verify MPIN</button>
                  </form>
                </div>
              </>
            ) : (
              state === "otp" && (
                <>
                  <div className="otp-modal">
                    <div className="otp-title">
                      <h2 className="title-name">Enter MPIN</h2>
                    </div>

                    <OtpInputBox
                      onOtpChange={handleOtpChange}
                      otpValue={otpValue}
                      otpInputName={"SelfRedeem"}
                    />
                    <div className="main-login-container">
                      <button onClick={handleSubmitOtp} className="otp-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </RedeemDashboardContainer>
  );
};

export default RedeemDashboard;
