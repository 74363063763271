import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import img from "../../assets/images/Error-404.png";

const NotFoundPage = () => {
  return (
    <div>
      <Header />
      <div
        style={{ backgroundColor: "rgb(240, 247, 255)" }}
        className="d-flex justify-content-center align-items-center p-3"
      >
        <img className="img-fluid" src={img} alt="access denied" />
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
