import { styled } from "styled-components";

export const RedeemPackagesPageContainer = styled.div`
  .mainCardSection {
    .card-title {
      font-size: 30px;
      font-weight: 700;
    }
    .profile1Content {
      background-color: #f0f7ff;
      border-radius: 20px;
      padding: 20px;
      .mobileHeader {
        display: none;
        font-size: 30px;
        font-weight: 700;
      }
      .pc {
        display: flex;
        flex-flow: wrap;
        width: 100%;
      }
      .pct {
        flex-grow: 0;
        flex-basis: 18%;
        max-width: 18%;
        font-weight: 700;
      }
      .pcc {
        padding-left: 10px;
        flex-grow: 0;
        flex-basis: 76%;
        max-width: 76%;
      }
    }
  }
  @media (max-width: 767px) {
    .mainCardSection {
      .card-title {
        display: none;
      }
      .profile1Content {
        .mobileHeader {
          display: block;
          font-size: 24px;
          font-weight: 700;
          border-bottom: 1px solid #c6ced8;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }

        .pct {
          flex-basis: 28%;
          max-width: 28%;
        }
        .pcc {
          flex-basis: 66%;
          max-width: 66%;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .mainCardSection {
      .card-title {
        display: none;
      }
      .profile1Content {
        padding: 15px;
        .mobileHeader {
          font-size: 18px;
        }

        .pct {
          font-size: 12px;
          flex-basis: 36%;
          max-width: 36%;
          padding-top: 4px;
        }
        .pcc {
          font-size: 12px;
          flex-basis: 60%;
          max-width: 60%;
          padding-top: 4px;
        }
      }
    }
  }
`;
