import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { PartnerForBusinessPageContainer } from "./PartnerForBusinessPageContainer";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import partnerImg from "../../assets/images/business.png";
import increase from "../../assets/images/business-partner/increase.png";
import medal from "../../assets/images/business-partner/medal.png";
import loyalty from "../../assets/images/business-partner/loyalty-program.png";
import engagement from "../../assets/images/business-partner/user-engagement.png";
import marketing from "../../assets/images/business-partner/stock-market.png";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils/EmailRegex";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import { toastMessage } from "../../utils/toastUtils";

const PartnerForBusinessPage = () => {
  const [customizeCheck, setCustomizeCheck] = useState(false);

  const handleCustomizeCheck = (e) => {
    setCustomizeCheck(e.target.checked);
  };

  const partnerBusinessFormSchema = yup.object().shape({
    businessName: yup.string().required("Business Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(emailRegex, "Enter Valid Email"),
    mobilenumber: yup
      .string()
      .required("Mobile Number is required")
      .matches(/^(\+91[-\s]?)?[0]?[6789]\d{9}$/, "Enter Valid Mobile Number")
      .max(10, "Enter Valid Mobile Number"),
    pincode: yup
      .string()
      .notRequired()
      .matches(
        /^[1-9][0-9]{5}$/,
        "Invalid pincode. It should be a 6-digit number."
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      businessName: "",
      email: "",
      mobilenumber: "",
      pincode: "",
      address: "",
    },
    resolver: yupResolver(partnerBusinessFormSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/businessinquiry/save`,
      "POST",
      {
        businessInquiryType: "Partnership",
        partnerEmail: data?.email,
        partnerMobile: data?.mobilenumber,
        businessName: data?.businessName,
        email: data?.email,
        mobileNo: data?.mobilenumber,
        isInterested: customizeCheck ? 1 : 0,
        address: data?.address,
      }
    );

    if (res?.data?.status) {
      toastMessage(res?.data?.message, "success");
      reset();
    } else {
      toastMessage(res?.data?.message, "error");
    }

    setCustomizeCheck(false);
  };

  return (
    <PartnerForBusinessPageContainer>
      <Header />
      <div className="allContent">
        <div style={{ padding: "20px 0" }}>
          <Container>
            <Row>
              <Col lg={12}>
                <div>
                  <Card className="customCard p-2">
                    <Card.Body>
                      <Row>
                        <Col lg={7} xs={12} className="wwaLeft">
                          <div className="top-content">
                            <span className="title">
                              Unlock New Opportunities for Your Business!
                            </span>
                          </div>
                          <div className="sub-title mt-2">
                            <span className="sub-title-content">
                              Join SmaartCard and connect with thousands of
                              customers looking for exclusive deals from trusted
                              businesses like yours
                            </span>
                          </div>
                          <div className="sub-title mt-2">
                            <span className="sub-title-content">
                              Partnering with us gives you the tools to grow
                              your business, increase foot traffic, and build
                              long-term loyalty through our innovative platform.
                            </span>
                          </div>
                        </Col>
                        <Col lg={5} xs={12} className="wwaRight">
                          <div className="img-side">
                            <img
                              src={partnerImg}
                              alt="partner_Img"
                              className="img-fluid"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div>
                  <Card className="partner-section">
                    <Card.Body>
                      <div className="title-section">
                        <span className="title">
                          Why Partner with SmaartCard?
                        </span>
                      </div>

                      <div className="mt-2">
                        <Row>
                          <Col className="p-2" lg={6}>
                            <div className="partner-content p-1">
                              <img
                                className="partner-icon"
                                src={increase}
                                alt="increase"
                              />

                              <div>
                                <div className="content-title">
                                  <span>Increased Customer Footfall</span>
                                </div>
                                <div className="content">
                                  <p>
                                    Attract new customers by offering exclusive
                                    discounts and deals through SmaartCard,
                                    driving more traffic to your business.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="p-2" lg={6}>
                            <div className="partner-content p-1">
                              <img
                                className="partner-icon"
                                src={medal}
                                alt="medal"
                              />

                              <div>
                                <div className="content-title">
                                  <span>Enhanced Brand Visibility</span>
                                </div>
                                <div className="content">
                                  <p>
                                    Get featured on our app and website,
                                    increasing your visibility among a wider
                                    audience actively seeking deals in your
                                    category.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="p-2" lg={6}>
                            <div className="partner-content p-1">
                              <img
                                className="partner-icon"
                                src={loyalty}
                                alt="loyalty"
                              />

                              <div>
                                <div className="content-title">
                                  <span>Loyalty Building</span>
                                </div>
                                <div className="content">
                                  <p>
                                    Reward customers with ongoing offers,
                                    turning one-time visitors into loyal patrons
                                    who return frequently.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="p-2" lg={6}>
                            <div className="partner-content p-1">
                              <img
                                className="partner-icon"
                                src={engagement}
                                alt="engagement"
                              />

                              <div>
                                <div className="content-title">
                                  <span>Real-Time Engagement</span>
                                </div>
                                <div className="content">
                                  <p>
                                    Connect with customers in real time through
                                    in-app notifications and updates about your
                                    latest offers, promotions, and events.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="p-2" lg={6}>
                            <div className="partner-content p-1">
                              <img
                                className="partner-icon"
                                src={marketing}
                                alt="marketing"
                              />

                              <div>
                                <div className="content-title">
                                  <span>Cost-Effective Marketing</span>
                                </div>
                                <div className="content">
                                  <p>
                                    Promote your business to a targeted audience
                                    without the high costs of traditional
                                    marketing. Pay only when customers redeem
                                    offers, ensuring your marketing budget works
                                    efficiently.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <div className="brand-section">
                <Card>
                  <Card.Body>
                    <div className="brand-title">
                      <span>
                        Create a Personalized SmaartCard for Your Brand!
                      </span>
                    </div>

                    <div className="brand-subtitle">
                      <p>
                        SmaartCard offers businesses and organizations the
                        ability to create fully customized cards that reflect
                        their brand identity. Whether for employee rewards,
                        customer loyalty programs, or corporate gifting, a
                        personalized SmaartCard is a unique way to offer
                        exclusive benefits while enhancing your brand’s
                        visibility.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Row>
          </Container>
        </div>

        <div className="missionSection">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="kpt">
                  <span className="title">
                    Why Choose a Customized SmaartCard?
                  </span>
                </div>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-3">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-title">Branding Opportunities</div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                            Feature your company’s logo, colors, and design
                            elements to ensure the card aligns with your brand
                            identity. Every time the card is used, your brand is
                            at the forefront.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-3">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-title">Tailored Offers</div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                            Create exclusive deals and offers that are unique to
                            your business or organization, rewarding cardholders
                            with meaningful discounts and perks.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} xs={12} className=" mt-lg-0 mt-3">
                <Card className="kp">
                  <Card.Body>
                    <Row>
                      <Col xs={12}>
                        <div className="cr-title">Corporate Identity </div>
                      </Col>
                      <Col xs={12}>
                        <div className="cr-content">
                          <p>
                            Reinforce your corporate image by giving employees,
                            partners, or customers a custom-designed card that
                            reflects your organization’s professionalism and
                            values.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <div className="p-3">
              <span className="title-offer">Partner Benefits</span>
            </div>
            <Col lg={6} xs={12}>
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">01</span>

                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Customized Offers</span>
                    <span className="subtitle mt-1">
                      Create and manage your own exclusive deals to meet your
                      business goals.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} xs={12} className="mt-lg-0 mt-3">
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">02</span>
                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Data Insights</span>
                    <span className="subtitle mt-1">
                      Access valuable customer data and insights to refine your
                      offers and better understand your audience.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={6} className="mt-lg-3 mt-3">
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">03</span>
                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">No Upfront Costs</span>
                    <span className="subtitle mt-1">
                      Join for free and only give exclusive offers to smaratcard
                      users.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} xs={12} className="mt-lg-3 mt-3">
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">04</span>
                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Dedicated Support</span>
                    <span className="subtitle mt-1">
                      Get help from our team to set up, manage, and optimize
                      your offers on the SmaartCard platform.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <Card style={{ backgroundColor: "#f0f7ff", border: "none" }}>
                <Card.Body>
                  <Col>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div>
                        <span className="title">Partner with Us Today!</span>
                      </div>

                      <div>
                        <span className="subtitle">
                          Take your business to the next level with SmaartCard.
                          Start driving more customers and building long-term
                          loyalty by joining our growing network of partners.
                        </span>
                      </div>

                      <span>
                        Fill the below form for Partnership Inquiries:
                      </span>
                    </div>
                  </Col>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"businessName"}
                        errors={errors}
                        label={"Business Name"}
                        placeholder={"Enter Your Business Name"}
                      />
                    </Col>
                    <Col lg={6} className="mt-lg-0 mt-3">
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"email"}
                        errors={errors}
                        label={"Email"}
                        placeholder={"Enter Your Email"}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={3}>
                      <Input
                        type={"number"}
                        control={control}
                        register={register}
                        nameField={"mobilenumber"}
                        errors={errors}
                        label={"Mobile"}
                        placeholder={"Enter Your Mobile No."}
                      />
                    </Col>
                    <Col lg={3} className="mt-lg-0 mt-3">
                      <Input
                        type={"number"}
                        control={control}
                        register={register}
                        nameField={"pincode"}
                        errors={errors}
                        label={"Pincode"}
                        placeholder={"Enter Pincode"}
                      />
                    </Col>
                    <Col lg={6} className="mt-lg-0 mt-3">
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"address"}
                        errors={errors}
                        label={"Address"}
                        placeholder={"Enter Address Here"}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-center align-items-center mt-3 p-1">
                    <input
                      style={{ margin: "5px" }}
                      id="check"
                      name="check"
                      type="checkbox"
                      checked={customizeCheck}
                      onChange={handleCustomizeCheck}
                    />
                    <label
                      className="kplabel"
                      style={{ cursor: "pointer" }}
                      htmlFor="check"
                    >
                      Interested in customized smartcard?
                    </label>
                  </div>

                  <Col lg={12} className="text-center mt-3">
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      type="button"
                      variant="primary"
                      style={{ background: "#024089", borderColor: "#024089" }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </PartnerForBusinessPageContainer>
  );
};

export default PartnerForBusinessPage;
