import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  // Navigate,
} from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
// import { useState } from "react";
import LoginPage from "../pages/LoginPage/LoginPage";
import PartnerRegistration from "../pages/PartnerRegistration/PartnerRegistration";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import UserRegistration from "../pages/UserRegistration/UserRegistration";
import RedeemDashboard from "../pages/RedeemDashboard/RedeemDashboard";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import PartnerListingPage from "../pages/PartnerListingPage/PartnerListingPage";
import PartnerProfilePage from "../pages/PartnerProfilePage/PartnerProfilePage";
import RedeemPackagesPage from "../pages/RedeemPackagesPage/RedeemPackagesPage";
import PartnerDetailsPage from "../pages/PartnerDetailsPage/PartnerDetailsPage";
import CmsPage from "../pages/CmsPage/CmsPage";
import OwnedPackagesPage from "../pages/OwnedPackagesPage/OwnedPackagesPage";
import CategoryWisePartnerListingPage from "../pages/CategoryWisePartnerListingPage/CategoryWisePartnerListingPage";
import ScrollToTop from "../utils/ScrollToTop";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import PartnerRedeemLogsPage from "../pages/PartnerRedeemLogs/PartnerRedeemLogsPage";
import TermsAndConditionPage from "../pages/TermsAndConditionPage/TermsAndConditionPage";
import RedeemDashboardProfile from "../pages/RedeemDashboard/RedeemDashboardProfile/RedeemDashboardProfile";
import ProtectedRoutes from "./ProtectedRoutes";
import ContactUsPage from "../pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import PartnerForBusinessPage from "../pages/PartnerForBusinessPage/PartnerForBusinessPage";
import BulkDealForBusinessPage from "../pages/BulkDealForBusinessPage/BulkDealForBusinessPage";
import RedeemCoinsPageContainer from "../pages/RedeemDashboard/RedeemDashboardProfile/RedeemCoins/RedeemCoinsPage";
import RedeemCoinsPage from "../pages/RedeemDashboard/RedeemDashboardProfile/RedeemCoins/RedeemCoinsPage";

const AppRoutes = () => {
  const role = localStorage.getItem("role");
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/userRegistration" element={<UserRegistration />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/partnerRegistration" element={<PartnerRegistration />} />
        {role === "partner" ? (
          <Route
            path="/"
            element={<Navigate to="/partner-profile" replace />}
          />
        ) : (
          <Route path="/" element={<HomePage />} />
        )}

        {/* Define the partner profile route */}
        <Route
          path="/partner-profile"
          element={
            <ProtectedRoutes
              allowedRole={["partner"]}
              element={<PartnerProfilePage />}
            />
          }
        />

        {/* Partner routes */}

        <Route
          path="/partner-termsandcondition"
          element={
            <ProtectedRoutes
              element={<TermsAndConditionPage />}
              allowedRole={["partner"]}
            />
          }
        />

        <Route
          path="/redeemLogs"
          element={
            <ProtectedRoutes
              allowedRole={["partner"]}
              element={<PartnerRedeemLogsPage />}
            />
          }
        />

        <Route
          path="category/:categoryName/:id"
          element={<CategoryWisePartnerListingPage />}
        />

        <Route
          path="/partner/:partnerName/:partnerId"
          element={<PartnerDetailsPage />}
        />
        {/* {
          localStorage.getItem("role")  === "partner" &&
          <Route
          path="/partner-profile"
          element={
            <ProtectedRoutes
              allowedRole={["partner"]}
              element={<PartnerProfilePage />}
            />
          }
        />
        } */}

        <Route
          path="/owned-packages"
          element={
            <ProtectedRoutes
              element={<OwnedPackagesPage />}
              allowedRole={["partner"]}
            />
          }
        />

        <Route path="/partners" element={<PartnerListingPage />} />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoutes
              element={<UserProfilePage />}
              allowedRole={["user"]}
            />
          }
        />

        <Route
          path="/redeemed-coins-user"
          element={
            <ProtectedRoutes
              element={<RedeemCoinsPage />}
              allowedRole={["user"]}
            />
          }
        />
        <Route
          path="/redeemed-packages-user"
          element={
            <ProtectedRoutes
              element={<RedeemPackagesPage />}
              allowedRole={["user"]}
            />
          }
        />
        <Route
          path="/redeemdashboard-profile"
          element={
            <ProtectedRoutes
              element={<RedeemDashboardProfile />}
              allowedRole={["user", "partner"]}
            />
          }
        />

        <Route
          path="/redeemdashboard"
          element={
            <ProtectedRoutes
              element={<RedeemDashboard />}
              allowedRole={["user", "partner"]}
            />
          }
        />

        {/* CMS Pages */}
        <Route
          path="/AboutUs"
          element={<AboutUsPage title={"About Us"} menu={"AboutUs"} />}
        />
        <Route
          path="/TermsAndCondition"
          element={
            <CmsPage title={"Terms & Conditions"} menu={"TermsAndCondition"} />
          }
        />
        <Route
          path="/ContactUs"
          element={<ContactUsPage title={"Contact Us"} menu={"ContactUs"} />}
        />
        <Route
          path="/PrivacyPolicy"
          element={<CmsPage title={"Privacy Policy"} menu={"PrivacyPolicy"} />}
        />

        <Route
          path="/partnerforbusiness"
          element={<PartnerForBusinessPage />}
        />

        <Route
          path="/bulkdealforbusiness"
          element={<BulkDealForBusinessPage />}
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
