import React, { useEffect, useState } from "react";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import parse from "html-react-parser";
import { Card, Col, Container, Row } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import { CmsPageContainer } from "./CmsPageContainer";

const CmsPage = ({ menu, title }) => {
  const [cmsPageData, setCmsPageData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const getCmsDetails = async () => {
      setLoadingData(true);
      const cmsRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/cmsPages/fetchbyType/${menu}`,
        "GET",
        null
      );

      if (cmsRes?.data?.status) {
        setLoadingData(false);
        setCmsPageData(cmsRes?.data?.response);
      } else {
        setLoadingData(false);
        setCmsPageData([]);
      }
    };

    getCmsDetails();
  }, [menu]);

  if (loadingData) {
    return <Loader />;
  }

  return (
    <CmsPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <Card>
                <Card.Body>
                  <Card.Title className="cms-title">
                    <h2 className="title">{title}</h2>
                  </Card.Title>
                  {cmsPageData[0]?.cmsPageContent ? (
                    <Card.Text className="body">
                      {parse(cmsPageData[0]?.cmsPageContent || "")}
                    </Card.Text>
                  ) : (
                    <Card.Text>No Records Found</Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </CmsPageContainer>
  );
};

export default CmsPage;
