import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { TermsAndConditionPageContainer } from "./TermsAndConditionPageContainer";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import { MdDelete } from "react-icons/md";
import CommonModal from "../../components/Modal/CommonModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toastMessage } from "../../utils/toastUtils";
import Loader from "../../components/Loader/Loader";
import useResponsive from "../../Hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";

const TermsAndConditionPage = () => {
  const [tcData, setTcData] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [showTc, setShowTc] = useState(false);
  const [selectedTc, setSelectedTc] = useState(null); // NEW: Store selected T&C for editing
  const partnerId = localStorage.getItem("partnerId");

  const navigate = useNavigate();

  const { isMobile } = useResponsive();

  const tcSchema = yup.object().shape({
    termsAndConditionDescription: yup
      .string()
      .required("Description is required")
      .max(500, "Maximum length should be 500 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      termsAndConditionDescription: "",
    },
    resolver: yupResolver(tcSchema),
    mode: "onChange",
  });

  // Fetch T&C Data
  const tcDetails = async () => {
    setLoadingState(true);
    const tcRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/termsandcondition/${partnerId}/getall`,
      "GET",
      null
    );

    if (tcRes?.data?.status) {
      setTcData(tcRes?.data?.response?.TermsAndCondition);
    } else {
      setTcData([]);
    }
    setLoadingState(false);
  };

  useEffect(() => {
    tcDetails();
  }, [partnerId]);

  const handleCreateNewBtn = () => {
    setSelectedTc(null); // NEW: Clear selected T&C for new entry
    reset({ termsAndConditionDescription: "" }); // Reset form to empty values
    setShowTc(true);
  };

  const handleEdit = (tcObj) => {
    setSelectedTc(tcObj); // NEW: Store selected T&C data for editing
    reset({ termsAndConditionDescription: tcObj.termsAndConditionDescription }); // Pre-fill form with T&C data
    setShowTc(true);
  };

  const handleClose = () => {
    setShowTc(false);
  };

  const save = async (data) => {
    const url = selectedTc
      ? `${smaartCardUrl}/api/v1/dfordr/termsandcondition/${selectedTc.termsAndConditionId}` // Edit API
      : `${smaartCardUrl}/api/v1/dfordr/termsandcondition/0`; // Create API

    const res = await axiosFunction(url, "POST", {
      partnerId,
      termsAndConditionDescription: data.termsAndConditionDescription,
    });

    if (res?.data?.status) {
      toastMessage("Operation successful", "success");
      tcDetails();
      handleClose();
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  const onSubmit = (data) => save(data); // Reuse the save function

  const handleDelete = async (tcObj) => {
    const deleteRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/termsandcondition/${tcObj?.termsAndConditionId}/delete`,
      "DELETE",
      null
    );

    if (deleteRes?.data?.status) {
      toastMessage(deleteRes?.data?.response, "success");
      tcDetails();
    } else {
      toastMessage(deleteRes?.data?.message, "error");
    }
  };

  const modalBody = () => (
    <div className="d-flex justify-content-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          {...register("termsAndConditionDescription")}
          className="form-control"
          placeholder="Enter your terms and conditions here..."
          style={{ height: "200px" }}
          control={control}
        />
        <p className="error-message">
          {errors.termsAndConditionDescription?.message}
        </p>
        <Row className="mt-3">
          <Col xs="6" style={{ textAlign: "end" }}>
            <Button
              variant="primary"
              size="sm"
              onClick={handleClose}
              style={{
                background: "#e9eaec",
                borderColor: "#e9eaec",
                color: "#000",
                fontWeight: "500",
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="6" style={{ textAlign: "start" }}>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              style={{
                background: "#024089",
                borderColor: "#024089",
                fontWeight: "500",
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );

  if (loadingState) {
    return <Loader />;
  }

  return (
    <TermsAndConditionPageContainer>
      <Header />
      <div className="allContent content">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-center">
                      {isMobile && (
                        <button
                          onClick={() => navigate("/partner-profile")}
                          className="btn"
                        >
                          <IoChevronBack />
                        </button>
                      )}
                      <h2 className="card-title mb-0">Terms and Conditions</h2>
                    </div>{" "}
                    <FaPlusCircle
                      size={isMobile ? 20 : 26}
                      color={"#024089"}
                      className="m-3"
                      style={{ cursor: "pointer" }}
                      onClick={handleCreateNewBtn}
                    />
                  </Card.Title>
                  <Card.Text>
                    {tcData.map((tcObj) => (
                      <div
                        key={tcObj.termsAndConditionId}
                        className="tc-container"
                      >
                        <span className="tc-name">
                          {tcObj.termsAndConditionDescription}
                        </span>
                        <div className="d-flex ">
                          <MdDelete
                            size={isMobile ? 18 : 20}
                            className="delete-btn"
                            onClick={() => handleDelete(tcObj)}
                          />
                          <FaEdit
                            size={isMobile ? 18 : 20}
                            className="edit-btn"
                            onClick={() => handleEdit(tcObj)}
                          />
                        </div>
                      </div>
                    ))}
                    <CommonModal
                      show={showTc}
                      handleClose={handleClose}
                      modalTitle={selectedTc ? "Edit T & C" : "Create T & C"}
                      modalBody={modalBody()}
                      modalSize={"sm"}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </TermsAndConditionPageContainer>
  );
};

export default TermsAndConditionPage;
