import { styled } from "styled-components";

export const PartnerRegistrationContainer = styled.div`
  background-color: ${(props) => (props.isRespon ? "#ffffff" : "#f0f7ff")};
  .partner-parent {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .user-link {
    margin-top: 20px;
    display: flex;
    p {
      margin-right: 5px;
    }
  }

  .password-link {
    margin-top: 20px;
    display: flex;
    justify-content: right;

    p {
      margin-right: 5px;
    }
  }

  @media (max-width: 991px) {
    height: 100svh;
  }
`;
