import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { PartnerProfilePageContainer } from "./PartnerProfilePageContainer";
import { Card, Col, Container, Row } from "react-bootstrap";
import defaultImg from "../../assets/images/imagePlaceholder.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FcViewDetails,
  FcSimCardChip,
  FcDatabase,
  FcCopyright,
  FcBookmark,
} from "react-icons/fc";

const PartnerProfilePage = () => {
  const partnerData = useSelector((state) => state.partnerData);

  const navigate = useNavigate();

  return (
    <PartnerProfilePageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col lg={7}>
              <Card className="customCard">
                <Card.Body>
                  <div className="profileHead">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="imgContent">
                        <img
                          className="profileImg"
                          src={partnerData?.profilePhoto || defaultImg}
                          alt="profileimg"
                        />
                      </div>
                      <span className="profileName">{partnerData?.name}</span>
                    </div>
                  </div>
                  <div className="profileContent">
                    {/* <div className="pc">
                        <span className="pct mb-2">Id</span>:
                        <span className="pcc">{partnerData?.partnerId}</span>
                      </div> */}
                    {/* <div className="pc">
                      <span className="pct mb-2">Gender</span>:
                      <span className="pcc">{partnerData?.gender}</span>
                    </div> */}
                    <div className="pc">
                      <span className="pct mb-2">Email</span>:
                      <span className="pcc">{partnerData?.email}</span>
                    </div>
                    <div className="pc">
                      <span className="pct mb-2">Mobile No.</span>:
                      <span className="pcc">{partnerData?.mobile}</span>
                    </div>
                    <div className="pc">
                      <span className="pct mb-2">Address</span>:
                      <span className="pcc">{partnerData?.address}</span>
                    </div>
                    {/* <div className="pc">
                      <span className="pct mb-2">State</span>:
                      <span className="pcc">{partnerData?.state}</span>
                    </div> */}
                    <div className="pc">
                      <span className="pct mb-2">Customized Partner</span>:
                      <span className="pcc">
                        {partnerData?.isCustomized === 0 ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <div className="packages">
                <Card className="redeembtn">
                  <Card.Body onClick={() => navigate("/owned-packages")}>
                    <FcViewDetails
                      style={{ fontSize: "25px", marginRight: "5px" }}
                    />
                    View Owned Packages
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="redeembtn mt-3">
                  <Card.Body
                    onClick={() => {
                      localStorage.setItem(
                        "redeemPartnerId",
                        partnerData?.partnerId
                      );
                      navigate("/redeemDashboard");
                      localStorage.setItem("CardNumberVerified", "Redeem");
                    }}
                  >
                    <FcSimCardChip
                      style={{ fontSize: "25px", marginRight: "5px" }}
                    />
                    Redeem
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="redeembtn mt-3">
                  <Card.Body onClick={() => navigate("/redeemLogs")}>
                    <FcDatabase
                      style={{ fontSize: "25px", marginRight: "5px" }}
                    />
                    Redemeption Log
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="redeembtn mt-3">
                  <Card.Body
                    onClick={() => navigate("/partner-termsandcondition")}
                  >
                    <FcCopyright
                      style={{ fontSize: "25px", marginRight: "5px" }}
                    />
                    Terms & Conditions
                  </Card.Body>
                </Card>
              </div>
              {localStorage.getItem("isCustomizedForDeduction") == 1 && (
                <div>
                  <Card className="redeembtn mt-3">
                    <Card.Body
                      onClick={() => {
                        localStorage.setItem(
                          "redeemPartnerId",
                          partnerData?.partnerId
                        );
                        localStorage.setItem(
                          "CardNumberVerified",
                          "DeductionPoints"
                        );
                        navigate("/redeemDashboard");
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <FcBookmark
                          style={{ fontSize: "25px", marginRight: "5px" }}
                        />
                        <span style={{ marginRight: "10px" }}>
                          Deduction Points
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={7}></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </PartnerProfilePageContainer>
  );
};

export default PartnerProfilePage;
