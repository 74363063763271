import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { axiosFunction } from "../../../../axiosFunction";
import { smaartCardUrl } from "../../../../constant";
import { RedeemCoinsPageContainer } from "./RedeemCoinsPageContainer";
import Loader from "../../../../components/Loader/Loader";
import { formatDateString } from "../../../../utils/DateFormat";
import CommonModal from "../../../../components/Modal/CommonModal";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { HiMiniArrowTrendingDown } from "react-icons/hi2";

// import { RedeemCoinsPageContainer } from "./RedeemCoinsPageContainer";
// import { RedeemCoinTransactionModalContainer } from "./RedeemCoinTransactionModalContainer";

const RedeemCoinsPage = () => {
  const userId = localStorage.getItem("userId");

  const [redeemCoins, setRedeemCoins] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [show, setShow] = useState(false);
  const [transtionDetails, setTranstionsDetails] = useState([]);
  // const handleClose = () => {};

  const handleShow = async (pointsId) => {
    setShow(true);
    setTranstionsDetails(null);
    try {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/point/getAllTransactions/${pointsId}`,
        "GET",
        null
      );

      if (res?.data?.status) {
        setTranstionsDetails(res?.data?.response);
      } else {
        setTranstionsDetails([]);
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
      setTranstionsDetails([]);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const getRedeemCoins = async () => {
      setLoadingPackages(true);
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/point/${userId}`,
        "GET",
        null
      );
      if (res?.data?.status) {
        setLoadingPackages(false);
        setRedeemCoins(res?.data?.response);
      } else {
        setRedeemCoins([]);
        setLoadingPackages(false);
      }
    };

    getRedeemCoins();
  }, []);

  if (loadingPackages) {
    return <Loader />;
  }

  const modalContent = () => {
    return (
      <RedeemCoinsPageContainer>
        <div className="card-info coinPopUp">
          <div
            className="popUpContent"
            style={{ height: "50vh", overflowY: "scroll" }}
          >
            {transtionDetails?.map((transaction, index) => (
              <div
                key={index}
                className="transaction d-flex justify-content-between align-items-start mb-3"
                style={{ paddingRight: "30px" }}
              >
                <div className="transaction-details">
                  <span className="transaction-head">
                    {transaction.credit === 0.0
                      ? "Debit Point"
                      : "Credit Point"}
                  </span>{" "}
                  <br />
                  <span className="transaction-date">
                    Date: {transaction.createDate.split(" ")[0]}{" "}
                    {/* Extracts the date */}
                  </span>
                  <span className="transaction-time">
                    Time:{" "}
                    {transaction.createDate.split(" ")[1] +
                      " " +
                      transaction.createDate.split(" ")[2]}{" "}
                    {/* Extracts the time */}
                  </span>
                  <div>
                    <span className="transaction-time">
                      Description:{" "}
                      {transaction.credit === 0.0
                        ? transaction?.remarks || "-"
                        : transaction?.packageItemName || "-"}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    color: transaction.credit === 0.0 ? "red" : "green",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  {transaction.credit === 0.0 ? (
                    <HiMiniArrowTrendingUp />
                  ) : (
                    <HiMiniArrowTrendingDown />
                  )}
                  {transaction.credit === 0.0
                    ? transaction.debit
                    : transaction.credit}{" "}
                  Points
                </div>
              </div>
            ))}
          </div>
          <div className="total-points">
            <div>Total Remaining Points</div>
            <div>
              {transtionDetails?.reduce((total, transaction) => {
                // Add credit and subtract debit
                return (
                  total + (transaction.credit || 0) - (transaction.debit || 0)
                );
              }, 0)}{" "}
              Points
            </div>
          </div>
        </div>
      </RedeemCoinsPageContainer>
    );
  };

  return (
    <RedeemCoinsPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Card.Title className="d-flex align-items-center justify-content-between">
              <h4 className="card-title">Redeemed Coins</h4>
            </Card.Title>
            {redeemCoins.length > 0 ? (
              redeemCoins.map((redeemCoins, index) => (
                <Col xs={12} md={6} key={index} className="mb-4">
                  <Card className="mainCardSection" style={{ border: "none" }}>
                    <Card.Body>
                      <Card.Text>
                        <div
                          className="profile1Content mb-lg-3 mb-2"
                          style={{ borderRadius: "5px" }}
                        >
                          <div className="mobileHeader">Redeemed Coins</div>

                          <div className="pc">
                            <span className="pct mb-2">Subscription Name</span>:
                            <span className="pcc">
                              {redeemCoins?.subscriptionName}
                            </span>
                          </div>
                          <div className="pc">
                            <span className="pct mb-2">Partner Name</span>:
                            <span className="pcc">
                              {redeemCoins?.partnerName}
                            </span>
                          </div>

                          <div
                            className="pc"
                            style={{ color: "#024089", fontWeight: "bold" }}
                          >
                            <span className="pct mb-2">Points</span>:
                            <span
                              className="pcc"
                              onClick={() => handleShow(redeemCoins.pointsId)}
                              style={{ textDecoration: "underline" }}
                            >
                              {redeemCoins?.points}
                            </span>
                          </div>

                          <CommonModal
                            modalTitle={redeemCoins?.subscriptionName}
                            show={show}
                            handleClose={handleClose}
                            modalBody={modalContent()}
                          />
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div
                className="m-4"
                style={{ backgroundColor: "#f0f7ff", padding: "10px" }}
              >
                No Redeem Packages Found.
              </div>
            )}
          </Row>
        </Container>
      </div>
      <Footer />
    </RedeemCoinsPageContainer>
  );
};

export default RedeemCoinsPage;
