import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (_, action) => {
      return action.payload;
    },
    resetUserData: () => {
      return initialState;
    },
  },
});

export const { setUserData, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
