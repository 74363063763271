import { styled } from "styled-components";

export const OffersContainer = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .filter-offer {
    display: inline-block;
    border-radius: 5px;
    line-height: 20px;
    margin: 5px 6px 0 0;
    padding: 10px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;

    @media (max-width: 991px) {
      font-size: 14px;
      padding: 8px;
    }

    @media (max-width: 757px) {
      padding: 6px;
    }
  }
`;
