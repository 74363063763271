import { ToastContainer } from "react-toastify";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme="light"
          />
          <AppRoutes />
          <Helmet>
            <title>offers and discounts | SmaartCard</title>
            <meta name="description" content="Explore our list of trusted partners." />
        <meta name="keywords" content="partners, business, trusted partners, listings" />
        <meta name="author" content="Smaart card" />
        <meta property="og:title" content="Smaart Card" />
        <meta property="og:description" content="Discover our trusted partners and learn more about them." />
        <meta property="og:type" content="website" />
          </Helmet>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
