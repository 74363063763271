import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PartnerListCard from "../../components/PartnerListCard/PartnerListCard";
import { PartnerListingPageContainer } from "../PartnerListingPage/PartnerListingPageContainer";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import usePartnerList from "../../Hooks/usePartnerList";
import Loader from "../../components/Loader/Loader";
import { Helmet } from "react-helmet";

const CategoryWisePartnerListingPage = () => {
  const partnerList = useSelector((state) => state.partnerList);
  const cityName = localStorage.getItem("partnerCity") || "";
  const offerId = localStorage.getItem("offerId") || 0;

  const { getPartnerList } = usePartnerList();

  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location.pathname.split("/").pop();

  useEffect(() => {
    getPartnerList(0, 100, cityName, categoryId, offerId);
  }, [cityName, categoryId, offerId]);

  const handleCardClick = (partnerObj) => {
    const partnerName = partnerObj?.partnerName
      .split(" ")
      .join("_")
      .toLowerCase();
    navigate(`/partner/${partnerName}/${partnerObj?.partnerId}`);
  };
  const helmetData = {
    Restaurant: {
      title: "offer for restaurant | food coupons - SmaartCard",
      description:
        "Good news for morbi. For get food coupons, pizza discounts, food offers near me contact us.",
      keywords:
        "offer for restaurant, pizza hut coupons, food coupons, food offers near me, pizza discounts",
      link: "https://smaartcard.com/category/restaurant",
    },
    Clinic: {
      title: "Exclusive Clinic Offers: Save on Your Healthcare Today!",
      description:
        "Offers on clinic services and treatments. Get discounts and special deals - book your appointment now and prioritize your health without breaking the bank!",
      keywords: "offer for clinic, best deal, discounts, clinic offer",
      link: "https://smaartcard.com/category/clinic",
    },
    "Physio and Fitness Center": {
      title:
        "Offer on Physio and Fitness Center Memberships - Limited Time Only!",
      description:
        "Our special offer at the Physio and Fitness Center. Enjoy top-notch physiotherapy and fitness programs designed to boost your health and well-being.",
      keywords:
        "Physio and Fitness Center, free coupons, offers and discounts, best discount offers",
      link: "https://smaartcard.com/category/physio_and_fitness_center",
    },
    Hospital: {
      title: "Exclusive Offers for Hospitals | Healthcare Discounts & Deals",
      description:
        "Discover our offer for hospitals, featuring exceptional discounts and benefits on a wide range of medical services. Explore now!",
      keywords:
        "Offers for Hospitals, best deal, discounts, discounts and benefits, times prime membership, discount app",
      link: "https://smaartcard.com/category/hospital",
    },
    "Home decor and Furniture": {
      title:
        "Exclusive Offer on Home Decor and Furniture - Transform Your Space Today",
      description:
        "Offers on stylish home decor and furniture. Shop now to enhance your living space with premium designs at discounted prices. Limited time offer!",
      keywords:
        "Offer on Home Decor and Furniture, discounted prices, Limited time offer",
      link: "https://smaartcard.com/category/home_decor_and_furniture",
    },
    "Game Zone": {
      title: "Exciting Offers in Game Zone - Unleash the Fun and Save Big!",
      description:
        "Discover the best offers in Game Zone! Enjoy exclusive discounts and special deals on top-rated games, accessories, and more.",
      keywords: "Offer in Game Zone, offers, amazon discount codes, promo code",
      link: "https://smaartcard.com/category/game_zone",
    },
    Jewellers: {
      title:
        "Exclusive Offers on Jewellery | Unbeatable Deals on Stunning Pieces",
      description:
        "Discover our offer for hospitals, featuring exceptional discounts and benefits on a wide range of medical services. Explore now!",
      keywords:
        "Offers for Hospitals, best deal, discounts, discounts and benefits, times prime membership, discount app",
      link: "https://smaartcard.com/category/jewellers",
    },
    "Pharmacy or Medical Store": {
      title:
        "Exclusive Offer on Medicines - Save Big on Your Prescriptions Today!",
      description:
        "Discover offers on medicines at our online pharmacy. Enjoy discounts and fast delivery on a wide range of prescription and over-the-counter medications.",
      keywords:
        "Offer on Medicines, offer for hospital, discount app, free coupons",
      link: "https://smaartcard.com/category/pharmacy_or_medical_store",
    },
    Laboratory: {
      title: "Get Offers on beauty and fashion | Smaartcard",
      description:
        "Explore unbeatable deals and discounts on the latest beauty and fashion trends. Hurry, grab the hottest deals before they're gone!",
      keywords:
        "beauty and fashion, Offer in Shopping, best discount offers, free gift cards",
      link: "https://smaartcard.com/category/laboratory",
    },
    "beauty and fashion": {
      title: "Get Offers on beauty and fashion | Smaartcard",
      description:
        "Explore unbeatable deals and discounts on the latest beauty and fashion trends. Hurry, grab the hottest deals before they're gone!",
      keywords:
        "beauty and fashion, Offer in Shopping, best discount offers, free gift cards",
      link: "https://smaartcard.com/category/beauty_and_fashion",
    },
    Gym: {
      title: "Exclusive Gym Membership Offers | Smaartcard",
      description:
        "Gym membership offers tailored to help you achieve your fitness goals without breaking the bank. Save big on memberships & embark your journey to a healthier lifestyle.",
      keywords:
        "Gym Membership Offers, Offer in gym, gym membership, Discounts on Fitness",
      link: "https://smaartcard.com/category/gym",
    },
    "Super Market": {
      title: "Supermarket Offers in Morbi | Save Big on Your Shopping Today",
      description:
        "Explore the latest supermarket offers in Morbi and enjoy fantastic discounts on groceries, household essentials, and more.",
      keywords:
        "Supermarket Offers in Morbi, Offer in Shopping, offers and discounts",
      link: "https://smaartcard.com/category/super_market",
    },
    cafe: {
      title: "Cafe Offers in Morbi - Indulge in Delicious Deals Today!",
      description:
        "Explore the latest cafe offers in Morbi! From discounts on gourmet coffee to tempting combo deals, find the perfect spot to satisfy your cravings.",
      keywords:
        "Cafe Offers in Morbi, offer for restaurant, pizza hut coupons, food coupons",
      link: "https://smaartcard.com/category/cafe",
    },
    "Fitness Equipment": {
      title: "Explore Discounts on Fitness Equipment Today",
      description:
        "Discover great discounts on high-quality fitness equipment to kickstart your journey towards a healthier lifestyle.",
      keywords:
        "Discounts on Fitness Equipment, Offer in Shopping, Offer in gym, gym",
      link: "https://smaartcard.com/category/fitness_equipment",
    },
    "Resort and Water Parks": {
      title: "Explore Exciting Offers at Waterpark & Resort",
      description:
        "Dive into adventure and relaxation with our exclusive offers at the waterpark & resort. Discover thrilling discounts, family packages, and more for the ultimate aquatic getaway.",
      keywords:
        "Offers at Our Waterpark & Resort, Offers in Waterpark, Offers in Resort, vacation Offer",
      link: "https://smaartcard.com/category/resort_and_water_parks",
    },
    Software: {
      title: "Unlock Savings with Exclusive Discounts on Software | Smaartcard",
      description:
        "Discover unbeatable discounts on top-rated software solutions! Explore our curated selection of discounted software offers now.",
      keywords:
        "Discounts on Software, discount app, free coupons, Software morbi",
      link: "https://smaartcard.com/category/software",
    },
    Electronics: {
      title: "Explore Irresistible Offers on Electronics Products",
      description:
        "Exclusive deals and discounts on a wide range of electronics products. From smartphones to laptops, find unbeatable offers to elevate your tech game.",
      keywords:
        "Offers on Electronics Products, Offer in Shopping, Offers on Mobile, Offers on Laptop",
      link: "https://smaartcard.com/category/electronics",
    },
    "Loan and Insurance": {
      title:
        "Unlock Incredible Deals: Loan and Insurance Services Offers Await!",
      description:
        "Discover unbeatable offers on loan and insurance services tailored to your needs. Don't miss out on exclusive deals and savings. Explore now!",
      keywords:
        "Loan and Insurance Services Offers, Offers in Loan and Insurance Services, offers and discounts",
      link: "https://smaartcard.com/category/loan_and_insurance",
    },
  };
  const data = helmetData[localStorage.getItem("category")];

  return (
    <PartnerListingPageContainer>
      {data && (
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
          <meta name="keywords" content={data.keywords} />
          <Link rel="canonical" href={data.link} />
          <meta name="author" content="Smaart card" />
          <meta property="og:title" content="Partner Listing Page" />
          <meta
            property="og:description"
            content="Discover our trusted partners and learn more about them."
          />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Header />
      <div className="allContent">
        <PartnerListCard
          partners={partnerList?.partners}
          handleCardClick={handleCardClick}
        />
      </div>
      <Footer />
    </PartnerListingPageContainer>
  );
};

export default CategoryWisePartnerListingPage;
