import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { formatDateString } from "../../utils/DateFormat";
import Loader from "../../components/Loader/Loader";
import { RedeemPackagesPageContainer } from "../RedeemPackagesPage/RedeemPackagesPageContainer";

const PartnerRedeemLogsPage = () => {
  const partnerId = localStorage.getItem("partnerId");

  const [redeemLogs, setRedeemLogs] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);

  useEffect(() => {
    const getRedeemLogs = async () => {
      // debugger
      setLoadingPackages(true);
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/redemptions/fetchbyPartner/${partnerId}`,
        "GET",
        null
      );
      if (res?.data?.status) {
        setLoadingPackages(false);
        setRedeemLogs(res?.data?.response?.redeemptionLogs);
      } else {
        setRedeemLogs([]);
        setLoadingPackages(false);
      }
    };

    getRedeemLogs();
  }, []);

  if (loadingPackages) {
    return <Loader />;
  }

  return (
    <RedeemPackagesPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col xs={12}>
              <div>
                <Card className="mainCardSection">
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center justify-content-between">
                      <div>
                        <h2 className="card-title">Redeem Logs</h2>
                      </div>
                    </Card.Title>
                    <Card.Text>
                      {redeemLogs?.length > 0 ? (
                        redeemLogs?.map((redeemObj) => {
                          return (
                            <div className="profile1Content mb-lg-3 mb-2">
                              <div className="mobileHeader">
                                Redeemed Packages
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Package Name</span>:
                                <span
                                  className="pcc"
                                  style={{ color: "#024089", fontWeight: 700 }}
                                >
                                  {redeemObj?.packageName}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Description</span>:
                                <span className="pcc">
                                  {redeemObj?.packageItemDescription}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Redeem Count</span>:
                                <span className="pcc">
                                  {redeemObj?.redeemCount === -1
                                    ? "Unlimited"
                                    : redeemObj?.redeemCount}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Value</span>:
                                <span className="pcc">
                                  {redeemObj?.packageItemValue}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">User Name</span>:
                                <span
                                  className="pcc"
                                  style={{ color: "#f9841a", fontWeight: 700 }}
                                >
                                  {redeemObj?.patientName}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">User Mobile</span>:
                                <span className="pcc">
                                  {redeemObj?.patientPhone}
                                </span>
                              </div>
                              <div className="pc">
                                <span className="pct mb-2">Item Type</span>:
                                <span className="pcc">
                                  {redeemObj?.itemTypeName}
                                </span>
                              </div>

                              <div className="pc">
                                <span className="pct mb-2">Package Id</span>:
                                <span className="pcc">
                                  {redeemObj?.packageId}
                                </span>
                              </div>

                              <div className="pc">
                                <span className="pct mb-2">Time</span>:
                                <span className="pcc">
                                  {formatDateString(redeemObj?.modifiedOn)}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="m-4"
                          style={{
                            backgroundColor: "#f0f7ff",
                            padding: "10px",
                          }}
                        >
                          No Redeem Packages Found.
                        </div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </RedeemPackagesPageContainer>
  );
};

export default PartnerRedeemLogsPage;
