import axios from "axios";
import { toastMessage } from "./utils/toastUtils";

export const axiosFunction = async (
  url,
  method,
  body = null,
  fileType = false,
  responseType
) => {
  try {
    const response = await axios({
      url,
      method,
      headers: {
        "X-APIKEY": "dfordr",
        AgentName: "dfordrapp",
        Accept: "application/json",
        "Content-Type": fileType ? "multipart/form-data" : "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },

      data: method === "POST" || "DELETE" || "GET" ? body : null,
      responseType,
    });

    return response;
  } catch (error) {
    toastMessage(error?.message, "error");
  }
};
