import { useEffect, useState } from "react";
import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";

export const usePartnerCity = () => {
  const [city, setCity] = useState([]);

  useEffect(() => {
    const getCity = async () => {
      const cityRes = await axiosFunction(
        `${smaartCardUrl}/location/api/ParnterCity`,
        "GET",
        null
      );

      setCity(cityRes?.data);
    };
    getCity();
  }, []);

  return { city };
};
