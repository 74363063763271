import Swal from "sweetalert2";

export const swalUtils = async (
  title,
  text,
  confirmButtonText,
  cancelButtonText
) => {
  const result = await Swal.fire({
    title: title,
    icon: "info",
    confirmButtonColor: "#024089",
    cancelButtonColor: "#e9eaec",
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
    showCancelButton: true,
    text: text,
    customClass: {
      cancelButton: "custom-cancel-button", // Apply custom class to the cancel button
    },
  });

  return result;
};
