import React, { useEffect, useState } from "react";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import parse from "html-react-parser";
import { Card, Col, Container, Row } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import { ContactUsPageContainer } from "./ContactUsPageContainer";
import { useSelector } from "react-redux";
import { IoIosCall, IoMdMail } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";

const ContactUsPage = ({ menu }) => {
  const [contactUsData, setContactUsData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const generalSetting = useSelector((state) => state.generalSetting);

  useEffect(() => {
    const getContactDetails = async () => {
      setLoadingData(true);
      const cmsRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/cmsPages/fetchbyType/${menu}`,
        "GET",
        null
      );

      if (cmsRes?.data?.status) {
        setLoadingData(false);
        setContactUsData(cmsRes?.data?.response);
      } else {
        setLoadingData(false);
        setContactUsData([]);
      }
    };

    getContactDetails();
  }, [menu]);

  const emailAddress = generalSetting?.find(
    (setting) => setting?.type === "Email"
  )?.value;
  const phoneNumber = generalSetting?.find(
    (setting) => setting?.type === "Phone"
  )?.value;

  if (loadingData) {
    return <Loader />;
  }

  return (
    <ContactUsPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col lg={5}>
                <Card className="customCard p-2" style={{minHeight:"100%"}}>
                  <Card.Body>
                    <div className="contact-left-side">
                      <span className="title">We’d Love to Hear From You!</span>
                    </div>
                    <div className="sub-title mt-2">
                      <span className="sub-title-content">
                        Whether you have questions, want to partner with us, or
                        are interested in learning more about SmaartCard, feel
                        free to reach out through any of the channels below.
                      </span>
                    </div>
                  </Card.Body>
                </Card>
            </Col>
            <Col lg={7} className="mt-lg-0 mt-3" style={{minHeight:"100%"}}>
                <Card className="customCard2 p-2">
                  <Card.Body>
                    <div className="head-right-side">
                      <div className="info-content">
                        <div>
                          <span className="info">Contact Information:</span>
                        </div>
                        <div className="mt-2">
                          <div className="p-2">
                            <IoMdMail color="#f7851c" size={20} />
                            <span className="m-2">{emailAddress}</span>
                          </div>
                          <div className="p-2">
                            <IoIosCall color="#f7851c" size={20} />
                            <span className="m-2">
                              {phoneNumber}/{phoneNumber}
                            </span>
                          </div>
                          <div className="p-2 d-flex">
                            <FaMapMarkerAlt color="#f7851c" size={20} />
                            &nbsp;
                            <span className="locationKp">
                              {parse(contactUsData[0]?.cmsPageContent || "")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </ContactUsPageContainer>
  );
};

export default ContactUsPage;
