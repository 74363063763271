import { styled } from "styled-components";

export const PartnerProfilePageContainer = styled.div`
  .customCard {
    height: 100%;
    .card-body {
      padding: 35px;
      .profileHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaebed;
        padding-bottom: 15px;

        .imgContent {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          .profileImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
        .profileName {
          font-weight: 600;
          margin-left: 10px;
          font-size: 24px;
        }
      }
      .profileContent {
        padding-top: 20px;
        .pc {
          display: flex;
          flex-flow: wrap;
          width: 100%;
        }
        .pct {
          flex-grow: 0;
          flex-basis: 20.66%;
          max-width: 20.66%;
        }
        .pcc {
          padding-left: 10px;
          flex-grow: 0;
          flex-basis: 78.66%;
          max-width: 78.66%;
        }
      }
    }
  }
  .redeembtn {
    cursor: pointer;
    :hover {
      background-color: #f0f7ff;
      border-radius: 6px;
    }
  }

  .navititle {
    font-size: 22px;
    font-weight: 600;
  }
  .sd {
    background-color: #f0f7ff;
    .customCard {
      background-color: #f0f7ff;
    }
  }

  @media (min-device-width: 1199px) and (max-device-width: 1400px) {
    .customCard {
      .card-body {
        .profile1Content {
          .pct {
            flex-basis: 40.66%;
            max-width: 40.66%;
          }
          .pcc {
            flex-basis: 57.66%;
            max-width: 57.66%;
          }
        }
      }
    }
  }
  @media (min-device-width: 991px) and (max-device-width: 1200px) {
    .customCard {
      .card-body {
        /* padding: 20px; */
        .profileContent {
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
        .profile1Content {
          .pct {
            flex-basis: 43.66%;
            max-width: 43.66%;
          }
          .pcc {
            flex-basis: 54.66%;
            max-width: 54.66%;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .packages {
      margin-top: 10px;
    }
  }

  @media (min-device-width: 575px) and (max-device-width: 768px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
      }
    }
    .packages {
      margin-top: 10px;
    }
  }
  @media (min-device-width: 399px) and (max-device-width: 576px) {
    .customCard {
      .card-body {
        padding: 20px;
        .profileContent {
          .pct {
            flex-basis: 25%;
            max-width: 25%;
          }
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
      }
    }
  }
  @media (max-width: 400px) {
    .customCard {
      .card-body {
        padding: 10px;
        .profileHead {
          .profileName {
            font-size: 18px;
          }
        }
        .profileContent {
          .pc {
            font-size: 12px;
          }
          .pct {
            flex-basis: 28%;
            max-width: 28%;
          }
          .pcc {
            flex-basis: 70.66%;
            max-width: 70.66%;
          }
        }
        .profile1Content {
          .pc {
            font-size: 12px;
          }
          .pct {
            flex-basis: 38%;
            max-width: 38%;
          }
          .pcc {
            flex-basis: 60%;
            max-width: 60%;
          }
        }
      }
    }

    .navititle {
      font-size: 18px;
    }
  }
`;
