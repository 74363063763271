import React, { useEffect, useState } from "react";
import { HomePageContainer } from "./HomePageContainer";
import Slider from "react-slick";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import usePartnerList from "../../Hooks/usePartnerList";
import img1 from "../../assets/images/herosection.png";
import img2 from "../../assets/images/herosection.png";
import img3 from "../../assets/images/herosection.png";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import defaultPhoto from "../../assets/images/imagePlaceholder.png";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import mailBoxImg from "../../assets/images/mailBox.png";
import blogImg from "../../assets/images/blogImg.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import Loader from "../../components/Loader/Loader";
import { toastMessage } from "../../utils/toastUtils";
import { emailRegex } from "../../utils/EmailRegex";
import Accordian from "../../components/Accordian/Accordian";
import { swalUtils } from "../../utils/swalUtils";
import { useRazorpay } from "react-razorpay";
import useResponsive from "../../Hooks/useResponsive";
import { SvgIcon } from "../../components/SvgIcon/SvgIcon.jsx";
import { useGeneralSetting } from "../../Hooks/useGeneralSetting";
import { setUserData } from "../../store/User/UserSlice";
import banner1 from "../../assets/bannerImages/banner1.jpg";
import banner2 from "../../assets/bannerImages/banner2.jpg";
import banner3 from "../../assets/bannerImages/banner3.jpg";
import banner4 from "../../assets/bannerImages/banner4.jpg";
import OffersList from "../../components/OfferList/Offers";

// import { SvgIcon } from "../../components/SvgIcon/SvgIcon";

const HomePage = () => {
  const { Razorpay } = useRazorpay();

  const [emailSub, setEmailSub] = useState("");
  const [emailFranch, setEmailFranch] = useState("");

  const cityName = localStorage.getItem("partnerCity") || "";
  const { getPartnerList, loadingPartners } = usePartnerList();
  const partnerList = useSelector((state) => state.partnerList);
  const userData = useSelector((state) => state.userData);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const offerId = localStorage.getItem("offerId") || 0;

  const [showMoreCategory, setShowMoreCategory] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const [faqData, setFaqData] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(1);

  const { isMobile, isRespon } = useResponsive();

  const { getGeneralSetting } = useGeneralSetting();

  useEffect(() => {
    getGeneralSetting();
  }, []);

  useEffect(() => {
    const faqDetails = async () => {
      const faqRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/faq/getall`,
        "GET",
        null
      );

      if (faqRes?.data?.status) {
        setFaqData(faqRes?.data?.response?.FaqVo);
      } else {
        setFaqData([]);
      }
    };

    faqDetails();
  }, []);

  useEffect(() => {
    const getCategoryList = async () => {
      const catRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/master/categories/fetchall`,
        "GET",
        null
      );

      if (catRes?.data?.status) {
        setCategoryList(catRes?.data?.response);
      } else {
        setCategoryList([]);
      }
    };

    getCategoryList();
  }, []);

  useEffect(() => {
    getPartnerList(0, 100, cityName, 0, offerId);
  }, []);

  useEffect(() => {
    const getSubscriptionList = async () => {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/master/subcription/fetchall`,
        "GET",
        null
      );

      if (res?.data?.status) {
        setSubscriptionList(res?.data?.response);
      } else {
        setSubscriptionList([]);
      }
    };

    getSubscriptionList();
  }, []);

  const bannerArr = [banner1, banner2, banner3, banner4];

  const sliderSettings = {
    slidesToShow: isMobile && isRespon ? 1 : 1.65,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    speed: 500,
    infinite: true,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const subscribeSliderSettings = {
    slidesToShow:
      isMobile && isRespon
        ? 1
        : Math.min(
            3,
            subscriptionList?.filter((sub) => sub.isActive === 0).length
          ),
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    speed: 500,
    infinite: subscriptionList?.filter((sub) => sub.isActive === 0).length >= 3,
    autoplaySpeed: 2000,
    autoplay: true,
    // autoplay: subscriptionList?.filter((sub) => sub.isActive === 0).length >= 3,
    beforeChange: (current, next) => {
      const inactiveSubscriptions = subscriptionList?.filter(
        (sub) => sub.isActive === 0
      );

      const totalSlides = inactiveSubscriptions.length;
      const middleIndex = (next + Math.floor((3 - 1) / 2)) % totalSlides;
      setCurrentSlide(isMobile && isRespon ? next : middleIndex);
    },
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleShowCategory = () => {
    setShowMoreCategory(!showMoreCategory);
  };

  const handleCardClick = (categoryObj) => {
    localStorage.setItem("category", categoryObj?.typeName);
    const formattedTypeName = categoryObj?.typeName?.replace(/ /g, "_");
    navigate(`/category/${formattedTypeName}/${categoryObj?.categoryMasterId}`);
  };

  const handlePartnerClick = (partnerObj) => {
    const partnerName = partnerObj?.partnerName
      .split(" ")
      .join("_")
      .toLowerCase();
    navigate(`/partner/${partnerName}/${partnerObj?.partnerId}`);
  };

  const handleEmailChange = (e) => {
    setEmailSub(e.target.value);
  };

  const handleEmailSubscribe = async () => {
    if (!emailRegex.test(emailSub)) {
      toastMessage("Please enter a valid email address.", "error");
      return;
    }

    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/inquiry/save`,
      "POST",
      {
        inquiryType: "subscription",
        inquiryEmail: emailSub,
      }
    );

    if (res?.data?.status) {
      toastMessage("inquiry sent for subscription successfully", "success");
      setEmailSub("");
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleEmailFranchChange = (e) => {
    setEmailFranch(e.target.value);
  };

  const handleEmailFranchise = async () => {
    if (!emailRegex.test(emailFranch)) {
      toastMessage("Please enter a valid email address.", "error");
      return;
    }

    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/inquiry/save`,
      "POST",
      {
        inquiryType: "franchise",
        inquiryEmail: emailFranch,
      }
    );

    if (res?.data?.status) {
      toastMessage("inquiry sent for franchise successfully", "success");
      setEmailFranch("");
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleSubscribe = async (obj) => {
    const buyNowSwal = await swalUtils(
      "Buy Now",
      `Subscription Name: ${obj?.name}`,
      `Buy now ₹${obj?.price}`,
      "Cancel"
    );

    if (buyNowSwal?.isConfirmed) {
      if (!localStorage.getItem("userId")) {
        navigate("/login");
        return;
      } else {
        const paymentPayload = {
          patientId: userData.patientId,
          subscriptionId: obj?.subscriptionMasterId,
          amount: obj?.price * 100,
          price: obj?.price,
        };

        const paymentRes = await axiosFunction(
          `${smaartCardUrl}/api/v1/payment/create`,
          "POST",
          paymentPayload
        );

        if (paymentRes?.data?.status) {
          const options = {
            key: paymentRes?.data?.response.keyId,
            amount: obj?.price,
            currency: "INR",
            name: userData?.name,
            description: `Subscription Name: ${obj?.name}`,
            image: obj?.img,
            order_id: paymentRes?.data?.response?.orderId,
            handler: async function (paymentResponse) {
              const paymentPayload = {
                paymentId: paymentRes?.data?.response?.paymentId,
                razorpayPaymentId: paymentResponse?.razorpay_payment_id,
                razorpayOrderId: paymentResponse?.razorpay_order_id,
                razorpaySignature: paymentResponse?.razorpay_signature,
                status: "success",
              };
              const razorPayRes = await axiosFunction(
                `${smaartCardUrl}/api/v1/payment/update`,
                "POST",
                paymentPayload
              );

              if (razorPayRes?.data?.status) {
                const profileRes = await axiosFunction(
                  `${smaartCardUrl}/api/v1/dfordr/user/${localStorage.getItem(
                    "userId"
                  )}`,
                  "GET",
                  null
                );

                if (profileRes?.data?.status) {
                  dispatch(setUserData(profileRes?.data?.response));
                }

                toastMessage("Payment Successful!", "success");
              } else {
                toastMessage("Payment Failed!", "error");
              }
            },
            prefill: {
              name: userData?.patientName,
              email: userData?.email,
            },
            theme: {
              color: "#3085d6",
              MaxHeight: "70%",
            },
            modal: {
              ondismiss: function () {
                return true;
              },
            },
          };

          const rzp = new Razorpay(options);
          rzp.open();
          rzp.on("payment.failed", async function (response) {
            try {
              const payload = {
                paymentId: paymentRes?.data?.response?.paymentId,
                status: "failed",
                code: response.error.code,
                reason: response.error.reason,
              };
              const razorPayRes = await axiosFunction(
                `${smaartCardUrl}/api/v1/payment/update`,
                "POST",
                payload
              );

              if (razorPayRes?.data?.status) {
                toastMessage("Payment Failed!", "error");
              }
            } catch (error) {
              console.error("error", error);
            }
          });
        } else {
          toastMessage(paymentRes?.data?.message, "error");
        }
      }
    }
  };

  if (loadingPartners) {
    return <Loader />;
  }

  return (
    <HomePageContainer>
      <Header />

      <div className="mt-4 mb-4">
        <Slider {...sliderSettings}>
          {bannerArr.map((banner, index) => (
            <div key={index}>
              <img
                src={banner}
                alt={`Banner ${index + 1}`}
                className="imgSlider img-fluid"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="allContent">
        {categoryList?.length > 0 && (
          <div className="msection">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="sectionMainTitle">
                    <span className="sectionTitle">Category</span>

                    <div
                      onClick={handleShowCategory}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="sectionMore">
                        {showMoreCategory ? "Show Less" : "Show More"}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="partner-content">
                <Row>
                  {categoryList
                    ?.map((categoryObj) => {
                      return (
                        <Col
                          key={categoryObj?.partnerId}
                          md={3}
                          xs={6}
                          className="mt-2"
                        >
                          <Card
                            style={{ cursor: "pointer", height: "100%" }}
                            onClick={() => handleCardClick(categoryObj)}
                            className="partnerMainCard"
                          >
                            <Card.Body className="partnerCardContent">
                              <div className="partnerImgSection">
                                <img
                                  className="img-fluid"
                                  src={categoryObj?.categoryImg || defaultPhoto}
                                  alt="google_store_img"
                                />
                              </div>
                              <div className="productSectionTitle mt-lg-2">
                                {categoryObj?.typeName}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                    .slice(0, showMoreCategory ? categoryList.length : 8)}
                </Row>
              </div>
            </Container>
          </div>
        )}

        {subscriptionList?.filter((sub) => sub.isActive === 0).length > 0 && (
          <div className="msection" style={{ background: "#f0f7ff" }}>
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="sectionMainTitle">
                    <span className="sectionTitle">Subscription Details</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 mb-3 subs">
                  <Slider {...subscribeSliderSettings}>
                    {subscriptionList
                      ?.filter((sub) => sub.isActive === 0)
                      .map((subObj, index) => {
                        return (
                          <Card
                            key={subObj?.subscriptionMasterId}
                            className={`card ${
                              index === currentSlide ? "active" : ""
                            } `}
                          >
                            <div className="d-flex flex-column justify-content-center p-xxl-5 p-xl-4 p-lg-3 p-md-3 p-3">
                              <div>
                                <h3 className="sub-title">
                                  Subscription Details/ {subObj?.name}
                                </h3>
                              </div>

                              <div className="price">
                                ₹<span>{subObj?.price}</span>
                              </div>

                              <div
                                style={{
                                  fontSize: "16px",
                                  color: "grey",
                                }}
                              >
                                ₹
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {subObj?.discountPrice}
                                </span>
                              </div>

                              <hr style={{ color: "#fff;", opacity: 1 }} />

                              <div>
                                <div className="key-main">
                                  <span className="key-title">
                                    Key Features
                                  </span>
                                </div>
                                <ul
                                  style={{ listStyleType: "none" }}
                                  className="listfeature"
                                >
                                  <div className={"features-list"}>
                                    <li className="list">
                                      <SvgIcon
                                        active={index === currentSlide}
                                        className="svgiconfeature"
                                      />
                                      <p>
                                        Add up to {subObj?.maxFamilyMember}{" "}
                                        family members.
                                      </p>
                                    </li>
                                  </div>

                                  <div className={"features-list"}>
                                    <li className="list">
                                      <SvgIcon
                                        active={index === currentSlide}
                                      />
                                      <p> Small process of subscription.</p>
                                    </li>
                                  </div>

                                  <div className="features-list">
                                    <li className="list">
                                      <SvgIcon
                                        active={index === currentSlide}
                                      />
                                      <p>
                                        The maximum active duration is{" "}
                                        {subObj?.durationInDays} days.
                                      </p>
                                    </li>
                                  </div>
                                </ul>
                              </div>

                              <div className="mt-2">
                                <Button
                                  className="sub-btn"
                                  onClick={() => handleSubscribe(subObj)}
                                >
                                  Subscribe Now
                                </Button>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        <div className="msection">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="sectionMainTitle">
                  <span className="sectionTitle">Our Partners</span>

                  <div
                    onClick={handleShowCategory}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() => navigate("/partners")}
                      className="sectionMore"
                    >
                      Show More
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-2 mb-2">
              <Col>
                <OffersList />
              </Col>
            </Row>

            <div className="partner-content">
              <Row>
                {partnerList?.partners?.length > 0 ? (
                  partnerList?.partners
                    ?.map((partnerObj) => {
                      return (
                        <Col
                          key={partnerObj?.partnerId}
                          md={3}
                          xs={6}
                          className="mt-2"
                        >
                          <Card
                            style={{ cursor: "pointer", height: "100%" }}
                            onClick={() => handlePartnerClick(partnerObj)}
                            className="partnerMainCard"
                          >
                            <Card.Body className="partnerCardContent">
                              <div className="partnerImgSection">
                                <img
                                  className="img-fluid"
                                  src={partnerObj?.partnerPhoto || defaultPhoto}
                                  alt="google_store_img"
                                />
                              </div>
                              <div className="productSectionTitle mt-lg-2">
                                {partnerObj?.partnerName}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                    .slice(0, 8)
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#f0f7ff",
                      borderRadius: "5px",
                      lineHeight: "20px",
                      padding: "20px",
                      color: "#024089",
                      marginTop: "20px",
                    }}
                  >
                    No Partner Found
                  </div>
                )}
              </Row>
            </div>
          </Container>
        </div>

        <div className="msection" style={{ background: "#024089" }}>
          <Container>
            <Row>
              <Col xs={12}>
                <div className="subscribeSection">
                  <Row className="subscribeContentSection">
                    <Col lg={12}>
                      <Row>
                        <Col lg={6}>
                          <div className="subscribeContent">
                            Subscribe to have new coupon lists delivered
                            directly to your inbox
                          </div>
                        </Col>
                        <Col lg={6} className="mt-md-0 mt-1">
                          <Row>
                            <Col xs={8}>
                              <input
                                placeholder="Enter your email address"
                                className="subscribeInput"
                                type="text"
                                value={emailSub}
                                onChange={handleEmailChange}
                              />
                            </Col>
                            <Col xs={4} className="btnkp">
                              <Button
                                onClick={handleEmailSubscribe}
                                className="subscribeBtn"
                                disabled={!emailRegex.test(emailSub)}
                              >
                                Subscribe
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="subscribeImgSection">
                    <img
                      src={mailBoxImg}
                      alt="mail Box img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="msection">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="sectionMainTitle">
                  <span className="sectionTitle">FAQs</span>
                </div>
              </Col>
            </Row>
            <div className="blog-content">
              <Row>
                <Col xs={12}>
                  {faqData?.map((item, index) => {
                    return (
                      <Accordian
                        key={item?.faqId}
                        header={item?.question}
                        body={item?.answer}
                      />
                    );
                  })}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Join Franchise */}
        <div id="franchise" className="msection">
          <Container>
            <div className="joinSection">
              <Row>
                <Col lg={{ span: 8, offset: 2 }} xs={12}>
                  <div className="franchiseSection text-center">
                    Join our franchise today and turn your entrepreneurial
                    dreams into reality!
                  </div>
                </Col>
                <Col
                  lg={{ span: 6, offset: 3 }}
                  xs={{ span: 11, offset: 1 }}
                  className="mt-lg-3 mt-2"
                >
                  <Row>
                    <Col lg={9} xs={8}>
                      <input
                        placeholder="Enter your email address"
                        className="franchiseInput"
                        type="text"
                        value={emailFranch}
                        onChange={handleEmailFranchChange}
                      />
                    </Col>
                    <Col lg={3} xs={4}>
                      <Button
                        onClick={handleEmailFranchise}
                        className="franchiseBtn"
                        disabled={!emailRegex.test(emailFranch)}
                      >
                        Join
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>

      <Footer />
    </HomePageContainer>
  );
};

export default HomePage;
