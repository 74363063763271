import { useEffect, useState } from "react";
import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";

export const useGetOffers = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      const offerRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/master/offers/fetchAll`,
        "GET",
        null
      );

      setOffers(offerRes?.data?.response);
    };
    fetchOffers();
  }, []);

  return { offers };
};
