import React from "react";
import { InputContainer } from "./InputContainer";

const Input = ({
  label,
  type,
  placeholder,
  nameField,
  control,
  register,
  errors,
  ...props
}) => {
  return (
    <InputContainer>
      {label && <label className="label">{label}</label>}
      <input
        className="input-container"
        type={type}
        placeholder={placeholder}
        name={nameField}
        control={control}
        {...register(nameField)}
        {...props}
      />
      {errors[nameField] && (
        <p className="error-message">{errors[nameField].message}</p>
      )}
    </InputContainer>
  );
};

export default Input;
