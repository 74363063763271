import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const partnerDataSlice = createSlice({
  name: "partnerData",
  initialState,
  reducers: {
    setPartnerData: (_, action) => {
      return action.payload;
    },
    resetPartnerData: () => {
      return initialState;
    },
  },
});

export const { setPartnerData, resetPartnerData } = partnerDataSlice.actions;

export default partnerDataSlice.reducer;
