import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";
import { setUserData } from "../store/User/UserSlice";
import { toastMessage } from "./toastUtils";

export const verifyOtpBtn = async (mobileNo, otpValue, navigate, dispatch) => {
  const otpSubRes = await axiosFunction(
    `${smaartCardUrl}/api/v1/dfordr/general/verifyotp?phone=${mobileNo}&otp=${otpValue}`,
    "GET",
    null
  );

  if (otpSubRes?.data?.status) {
    dispatch(setUserData(otpSubRes?.data?.response));
    localStorage.setItem("userId", otpSubRes?.data?.response?.patientId);
    localStorage.setItem("role", "user");
    localStorage.removeItem("partnerId");
    navigate("/");
  } else {
    toastMessage(otpSubRes?.data?.message, "error");
  }
};
