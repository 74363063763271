import { useEffect, useState } from "react";
import { axiosFunction } from "../axiosFunction";
import { smaartCardUrl } from "../constant";

export const usePartnerState = () => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const getState = async () => {
      const cityRes = await axiosFunction(
        `${smaartCardUrl}/location/api/state/IN`,
        "GET",
        null
      );

      setState(cityRes?.data);
    };
    getState();
  }, []);

  return { state };
};
