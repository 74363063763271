import React from "react";
import { BulkDealForBusinessPageContainer } from "./BulkDealForBusinessPageContainer";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import partnerImg from "../../assets/images/business.png";
import employee_benefit from "../../assets/images/bulkdeal-partner/employee-benefits.png";
import benefits from "../../assets/images/bulkdeal-partner/member-card.png";
import loyalty from "../../assets/images/bulkdeal-partner/customer-loyalty.png";
import rewards from "../../assets/images/bulkdeal-partner/reward.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/Input/Input";
import { emailRegex } from "../../utils/EmailRegex";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { toastMessage } from "../../utils/toastUtils";

const BulkDealForBusinessPage = () => {
  const bulkBusinessSchema = yup.object().shape({
    businessName: yup.string().required("Business Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(emailRegex, "Enter Valid Email"),
    mobilenumber: yup
      .string()
      .required("Mobile Number is required")
      .matches(/^(\+91[-\s]?)?[0]?[6789]\d{9}$/, "Enter Valid Mobile Number")
      .max(10, "Enter Valid Mobile Number"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      businessName: "",
      email: "",
      mobilenumber: "",
      card: "",
      address: "",
    },
    resolver: yupResolver(bulkBusinessSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/businessinquiry/save`,
      "POST",
      {
        businessInquiryType: "bulkdeal",
        partnerEmail: data?.email,
        partnerMobile: data?.mobilenumber,
        businessName: data?.businessName,
        email: data?.email,
        mobileNo: data?.mobilenumber,
        noOfCards: data?.card,
        address: data?.address,
      }
    );

    if (res?.data?.status) {
      toastMessage(res?.data?.message, "success");
      reset();
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  return (
    <BulkDealForBusinessPageContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <Card className="customCard p-2">
                  <Card.Body>
                    <Row>
                      <Col lg={7} xs={12} className="wwaLeft">
                        <div className="top-content">
                          <span className="title">
                            Order SmaartCard in Bulk for Your Organization!
                          </span>
                        </div>
                        <div className="sub-title mt-2">
                          <span className="sub-title-content">
                            SmaartCard offers exclusive bulk order options for
                            businesses, organizations, and institutions looking
                            to provide added value to employees, members, or
                            customers.
                          </span>
                        </div>
                        <div className="sub-title mt-2">
                          <span className="sub-title-content">
                            Our bulk orders are perfect for companies that want
                            to offer benefits, rewards, or discounts to a large
                            group of people while fostering loyalty and
                            engagement.
                          </span>
                        </div>
                      </Col>
                      <Col lg={5} xs={12} className="wwaRight">
                        <div className="img-side">
                          <img
                            src={partnerImg}
                            alt="partner_Img"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div>
                <Card className="partner-section">
                  <Card.Body>
                    <div className="title-section">
                      <span className="title">
                        Why Choose SmaartCard Bulk Orders?
                      </span>
                    </div>

                    <div className="mt-2">
                      <Row>
                        <Col className="p-2" md={6}>
                          <div className="partner-content p-1">
                            <img
                              className="partner-icon"
                              src={employee_benefit}
                              alt="employee_benefit"
                            />

                            <div>
                              <div className="content-title">
                                <span>Employee Benefits</span>
                              </div>
                              <div className="content">
                                <p>
                                  Offer your employees a range of discounts and
                                  offers across healthcare, dining, retail, and
                                  more, enhancing their well-being and
                                  satisfaction.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="p-2" md={6}>
                          <div className="partner-content p-1">
                            <img
                              className="partner-icon"
                              src={rewards}
                              alt="rewards"
                            />

                            <div>
                              <div className="content-title">
                                <span>Corporate Gifting</span>
                              </div>
                              <div className="content">
                                <p>
                                  Use SmaartCard as a unique and practical gift
                                  for clients, partners, or employees, providing
                                  them access to exclusive deals and savings.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col className="p-2" md={6}>
                          <div className="partner-content p-1">
                            <img
                              className="partner-icon"
                              src={loyalty}
                              alt="loyalty"
                            />

                            <div>
                              <div className="content-title">
                                <span>Customer Loyalty</span>
                              </div>
                              <div className="content">
                                <p>
                                  Reward your most loyal customers by giving
                                  them SmaartCards, helping them save while
                                  building stronger relationships with your
                                  brand.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="p-2" md={6}>
                          <div className="partner-content p-1">
                            <img
                              className="partner-icon"
                              src={benefits}
                              alt="benefits"
                            />

                            <div>
                              <div className="content-title">
                                <span>Member Benefits</span>
                              </div>
                              <div className="content">
                                <p>
                                  Provide added value to members of your
                                  organization, association, or club by offering
                                  them access to a wide array of partner
                                  discounts.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} className="my-lg-3 mt-3 mb-2">
              <span className="title-offer">Benefits of Bulk Orders</span>
            </Col>
            <Col lg={6} xs={12}>
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">01</span>

                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Discounted Rates</span>
                    <span className="subtitle mt-1">
                      Enjoy special pricing for bulk orders, saving your
                      organization money while offering more value to
                      recipients.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} xs={12} className="mt-lg-0 mt-3">
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">02</span>
                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Custom Branding</span>
                    <span className="subtitle mt-1">
                      Personalize the cards with your company’s logo and
                      branding to make it a seamless part of your corporate
                      identity.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={6} className="mt-lg-3 mt-3">
              <Card style={{ minHeight: "100%" }}>
                <Card.Body>
                  <span className="section-no">03</span>
                  <div className="d-flex flex-column mt-lg-2 mt-3">
                    <span className="title">Flexible Quantities</span>
                    <span className="subtitle mt-1">
                      Order the exact number of cards you need, with flexible
                      options to fit organizations of any size.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-3">
            <Col>
              <Card style={{ backgroundColor: "#f0f7ff", border: "none" }}>
                <Card.Body>
                  <Col>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div>
                        <span className="title">Get Started Today!</span>
                      </div>

                      <div>
                        <span className="subtitle">
                          Order SmaartCards in bulk and provide your recipients
                          with exclusive savings and rewards they’ll love.
                          Whether for employees, customers, or members,
                          SmaartCard is the perfect solution for adding value
                          and promoting loyalty.
                        </span>
                      </div>

                      <span>For Bulk Order Inquiries:</span>
                    </div>
                  </Col>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"businessName"}
                        errors={errors}
                        label={"Business Name"}
                        placeholder={"Enter Your Business Name"}
                      />
                    </Col>
                    <Col lg={6} className="mt-lg-0 mt-3">
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"email"}
                        errors={errors}
                        label={"Email"}
                        placeholder={"Enter Your Email"}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col lg={6}>
                      <Input
                        type={"number"}
                        control={control}
                        register={register}
                        nameField={"mobilenumber"}
                        errors={errors}
                        label={"Mobile"}
                        placeholder={"Enter Your Mobile No."}
                      />
                    </Col>
                    <Col lg={6} className="mt-lg-0 mt-3">
                      <Input
                        type={"number"}
                        control={control}
                        register={register}
                        nameField={"card"}
                        errors={errors}
                        label={"No. of Cards"}
                        placeholder={"Enter Card No."}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={12} className="mt-lg-0 mt-1">
                      <Input
                        type={"text"}
                        control={control}
                        register={register}
                        nameField={"address"}
                        errors={errors}
                        label={"Address"}
                        placeholder={"Enter Address Here"}
                      />
                    </Col>
                  </Row>

                  <Col lg={12} className="text-center mt-3">
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      type="button"
                      variant="primary"
                      style={{ background: "#024089", borderColor: "#024089" }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </BulkDealForBusinessPageContainer>
  );
};

export default BulkDealForBusinessPage;
