import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { LoginPageContainer } from "./LoginPageContainer";
import CompanyLogo from "../../components/Logo/CompanyLogo";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import useResponsive from "../../Hooks/useResponsive";
import OtpInputBox from "../../components/OtpInputBox/OtpInputBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { toastMessage } from "../../utils/toastUtils";
import { useDispatch } from "react-redux";
import { verifyOtpBtn } from "../../utils/verifyOtpBtn";

const LoginPage = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();

  const { isMobile, isRespon } = useResponsive();

  const [otpScreen, setOtpScreenPage] = useState(false);

  const [otpValue, setOtpValue] = useState("");

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const mobileNumberSchema = yup.object().shape({
    mobileNo: yup
      .string()
      .required("Please Enter Mobile Number")
      .matches(/^(\+91[-\s]?)?[0]?[6789]\d{9}$/, "Enter Valid Mobile Number")
      .max(10, "Enter Valid Mobile Number"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      mobileNo: "",
    },
    resolver: yupResolver(mobileNumberSchema),
    mode: "onChange",
  });

  const otpSendFunction = async (mobNo) => {
    const otpRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/otp/send`,
      "POST",
      { phone: mobNo }
    );

    if (otpRes?.data?.status) {
      toastMessage(otpRes?.data?.message, "success");
    }
  };

  const onSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/checkMobileNumber?mobileNo=${data.mobileNo}`,
      "GET",
      null
    );

    if (res?.data?.status) {
      if (!res?.data?.response) {
        navigate("/userRegistration");
        localStorage.setItem("mobNo", data.mobileNo);
      } else {
        setOtpScreenPage(true);
        otpSendFunction(data.mobileNo);
      }
    }
  };

  const handleSubmitOtp = () => {
    verifyOtpBtn(getValues("mobileNo"), otpValue, navigate, dispatch);
  };

  const handleResendLink = () => {
    otpSendFunction(getValues("mobileNo"));
    setOtpValue("");
  };

  useEffect(() => {
    if (userId && role === "user") {
      navigate("/");
    }
  }, [navigate, userId, role]);

  if (userId && role === "user") {
    return null;
  }

  return (
    <LoginPageContainer isRespon={isRespon}>
      <div className="login-parent">
        <div className="login-modal">
          <div className="login-header">
            {!otpScreen ? (
              <>
                <div className="text-center mt-5">
                  <CompanyLogo companyLogo={logo} />
                </div>
                <h1 className="title-name">User Login</h1>

                <div className="main-login-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      label={"Mobile Number"}
                      type={"number"}
                      placeholder={"Enter Mobile No."}
                      control={control}
                      register={register}
                      nameField={"mobileNo"}
                      errors={errors}
                    />
                    <button type="submit" className="otp-btn">
                      Send OTP
                    </button>
                  </form>
                </div>

                <div className="partner-link">
                  <p>Are You a Partner? </p>
                  <Link to={"/partnerRegistration"}>Click Here</Link>
                </div>
              </>
            ) : (
              <>
                <div className="otp-modal">
                  <div className="otp-title">
                    <h2 className="title-name">Account Confirmation</h2>
                    <div>
                      <span>
                        We just sent you an confirmation code on your phone.
                      </span>
                      <span>Please enter the code to verify your account.</span>
                    </div>
                  </div>

                  <OtpInputBox
                    onOtpChange={handleOtpChange}
                    otpValue={otpValue}
                  />
                  <div className="main-login-container">
                    <button onClick={handleSubmitOtp} className="otp-btn">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="partner-link">
                  <p>Didn't receive the code? </p>
                  <Link onClick={handleResendLink}>Resend</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </LoginPageContainer>
  );
};

export default LoginPage;
